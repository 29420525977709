.icon {
  width: 25px;
  position: relative;
  height: 35px;
  object-fit: cover;
}
.aliVeli,
.irketAd {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.irketAd {
  font-size: var(--subhead-3-size);
  line-height: 12px;
  font-weight: 500;
  color: var(--color-dark-grey-v2);
}
.name,
.user {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.user {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}
.div {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}
.number {
  width: 24px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--color-accent-01);
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.addIcon {
  width: 8px;
  position: relative;
  height: 8px;
}
.icon1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xl);
  border: 2px solid var(--neutral-03);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.iconadd {
  display: none;
}
.iconadd,
.uiIconclosefilled {
  cursor: pointer;
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconRightcouple1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.iconRightcouple,
.userParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iconRightcouple {
  justify-content: flex-end;
  text-align: center;
  color: var(--neutral-07);
}
.userParent {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-29xl);
}
.homePageIcon {
  width: 25px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.anaSayfa {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.div1 {
  position: absolute;
  top: 4px;
  left: 6.7px;
  letter-spacing: -0.01em;
  font-weight: 600;
}
.wrapper {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-03);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
  color: var(--text-text-white);
}
.hangarIcon {
  width: 23px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.left1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.container {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-07);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
}
.frame,
.wrapper1 {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.frame {
  background-color: var(--color-accent-01);
  display: none;
}
.wrapper1 {
  background-color: var(--color-accent-04);
}
.iconmenuIcon,
.wrapper2 {
  width: 24px;
  position: relative;
  height: 24px;
}
.wrapper2 {
  width: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-04);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem5 {
  width: 240px;
  border-radius: var(--br-5xs);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-xs);
}
.logoutIcon {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: cover;
}
.navItemParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-dark-grey-v2);
}
.divider,
.navItemParent,
.top {
  align-self: stretch;
}
.top {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.divider {
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--color-dark-grey);
  height: 2px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.2;
}
.dividerParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.image6Icon,
.tr {
  position: relative;
}
.tr {
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.image6Icon {
  width: 30px;
  border-radius: var(--br-9xs);
  height: 20px;
  object-fit: cover;
}
.toggle {
  background-color: var(--text-text-white);
  box-shadow: var(--button-01);
  overflow: hidden;
  gap: var(--gap-5xs);
}
.toggle,
.toggle1 {
  flex: 1;
  border-radius: var(--br-13xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
}
.navigationDrawer,
.switch {
  align-self: stretch;
  display: flex;
}
.switch {
  border-radius: var(--br-21xl);
  background-color: var(--color-whitesmoke-200);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
}
.navigationDrawer {
  width: 300px;
  background-color: var(--text-text-white);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  box-sizing: border-box;
}
.label {
  width: 36px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}
.searchSmIcon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.helpIcon {
  width: 16px;
  position: relative;
  height: 16px;
  display: none;
}
.hintText,
.input {
  color: var(--color-slategray);
}
.input {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--color-lightgray-100);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-sm);
  gap: var(--gap-5xs);
  font-size: var(--text-md-regular-size);
}
.hintText {
  width: 320px;
  position: relative;
  line-height: 20px;
  display: none;
}
.inputField,
.inputFieldBase {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputFieldBase {
  align-self: stretch;
  gap: var(--gap-7xs);
}
.inputField {
  flex: 1;
  overflow: hidden;
}
.bell02Icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.bellChild {
  width: 8px;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 14.5px;
  border-radius: 50%;
  background-color: var(--color-crimson);
  height: 8px;
  z-index: 1;
}
.bell,
.menu01Parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.bell {
  align-items: flex-start;
  position: relative;
  gap: var(--gap-5xs);
}
.menu01Parent {
  align-self: stretch;
  background-color: #F5F5F5;
  align-items: center;
  padding: var(--padding-5xs) var(--padding-base);
  gap: 0 16px;
}
.productsList1 {
  position: relative;
  font-weight: 600;
}
.shape {
  border-radius: 6.32px;
  background-color: var(--color-mediumslateblue);
}
.btnSend,
.shape {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.componenticonicPlus {
  position: absolute;
  height: 66.56%;
  width: 66.44%;
  top: 16.56%;
  right: 16.78%;
  bottom: 16.88%;
  left: 16.78%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.add {
  width: 29.2px;
  cursor: pointer;
  position: relative;
  height: 32px;
}
.productsList {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--padding-3xs);
  font-size: var(--heading-3-size);
  color: var(--color-gray-100);
}
.image7Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 15px;
}
.icon2 {
  width: 40px;
  height: 25px;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  
}
.image7Parent {
  width: 35px;
  position: relative;
  height: 25px;
}
.canKra {
  position: relative;
  line-height: 110%;
}
.aFirmas {
  position: relative;
  font-size: var(--subhead-2-size);
  color: var(--color-lightslategray-100);
}
.canKraParent {
  gap: var(--gap-5xs);
}
.canKraParent,
.frameWrapper1,
.groupParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.groupParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-3xs);
}
.frameChild {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.phoneIcon {
  width: 22px;
  position: relative;
  height: 22px;
  object-fit: cover;
}
.phoneParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.krckrccorpnet {
  position: relative;
}
.emailSignParent,
.frameParent1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.emailSignParent {
  height: 22px;
  align-items: center;
  gap: var(--gap-5xs);
}
.frameParent1 {
  align-self: stretch;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--gap-mini);
  font-size: var(--subhead-3-size);
  color: var(--color-lightslategray-100);
}
.notAlannotAlannot {
  position: relative;
  line-height: 110%;
  font-weight: 500;
}
.notAlannotAlannotAlanWrapper {
  border-radius: var(--br-mini);
  background-color: var(--color-deepskyblue);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-8xs);
  font-size: var(--subhead-2-size);
  color: var(--color-darkgray-100);
}
.frameContainer,
.frameWrapper {
  display: flex;
  border-radius: 15px;

  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.frameContainer {
  align-self: stretch;
  border-radius: 15px;
  background-color: var(--text-text-white);
  box-shadow: 2px 12px 20px rgba(90, 117, 167, 0.04);
  padding: var(--padding-xl) var(--padding-base);
  gap: var(--gap-xl);
}
.frameWrapper {
  width: 375px;
  border-radius: 15px;
}
.frameGroup,
.frameParent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.frameGroup {
  align-self: stretch;
  flex-direction: row;
  margin-left: 25px;
  flex-wrap: wrap;
  padding: var(--padding-3xs) var(--padding-8xs);
  gap: var(--gap-6xl);
  font-size: var(--text-md-regular-size);
  color: var(--color-gray-400);
  font-family: var(--font-poppins);
}
.frameParent {
  flex: 1;
  flex-direction: column;
  font-size: var(--subhead-2-size);
  color: var(--color-darkslategray-200);
}
.musteriListesi {
  width: 100%;
  position: relative;
  background-color: var(--color-gray-200);
  height: 982px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--base-1-semibold-size);
  color: var(--color-light-body-text);
  font-family: var(--text-md-regular);
}
