.profit {
    position: relative;
    letter-spacing: 0.1px;
    font-weight: 600;
  }
  .selectorIcon {
    width: 39px;
    position: relative;
    height: 3px;
    object-fit: contain;
  }
  .profitGroup,
  .profitParent {
    display: flex;
    flex-direction: column; 
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-lg);
  }
  .profitGroup {
    color: var(--color-black);
  }
  .frameParent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-23xl);
  }
  .separatorIcon {
    width: 300px;
    position: relative;
    height: 1px;
  }
  .tabMenu {
    align-self: stretch;
    border-radius: var(--br-mini) var(--br-mini) 0 0;
    background-color: var(--text-text-white);
    height: 93.1px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--padding-mini) 0;
    border-radius: 15px;

    box-sizing: border-box;
    font-size: var(--font-size-5xl);
    color: var(--color-mediumslateblue);
    font-family: var(--text-md-lineheight-6-font-normal);
  }
  .smallLabel {
    position: relative;
    display: inline-block;
  border: none;
  outline: none;
    font-weight: 600;
  }
  .container,
  .label,
  .placeholderWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .label {
    justify-content: flex-start;
    font-size: var(--font-size-5xl);
  }
  .container,
  .placeholderWrapper {
    justify-content: center;
  }
  .container {
    border-radius: var(--br-8xs);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    height: 22px;
    flex-direction: column;
    padding: 0 var(--padding-xs);
    text-align: center;
    color: var(--color-dimgray-300);
  }
  .smallLabel1 {
    width: 154px;
    position: relative;
    font-weight: 600;
    display: none;
  }
  .label1,
  .labelParent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .label1 {
    width: 28px;
    height: 15px;
    justify-content: flex-start;
  }
  .labelParent {
    justify-content: center;
    gap: var(--gap-5xs);
  }
  .container1,
  .password1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .container1 {
    border-radius: var(--br-11xs);
    height: 22px;
    align-items: flex-start;
    padding: 0 var(--padding-xs);
    box-sizing: border-box;
    text-align: center;
    color: var(--color-dimgray-300);
  }
  .password1 {
    align-self: stretch;
    align-items: center;
    gap: var(--gap-5xs);
  }
  .xlLabel {
    position: relative;
    font-weight: 800;
  }
  .ctaButton,
  .password {
    align-items: center;
    box-sizing: border-box;
  }
  .ctaButton {
    width: 223px;
    cursor: pointer;
    border-radius: var(--br-5xs);
    background-color: green;
    height: 37px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: var(--padding-xs) 0;
    font-size: var(--text-lg-lineheight-7-font-semibold-size);
    color: var(--text-text-white);
  }
  .password {
    align-self: stretch;
    background-color: var(--text-text-white);
    height: 157.5px;
    padding: var(--padding-3xs) 0;
    gap: var(--gap-xs);
  }
  .container2,
  .password,
  .password3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .container2 {
    border-radius: var(--br-8xs);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    height: 22px;
    align-items: flex-start;
    padding: 0 var(--padding-xs);
    text-align: center;
    color: var(--color-dimgray-300);
  }
  .password3 {
    align-self: stretch;
    align-items: center;
  }
  .password2 {
    align-self: stretch;
    border-radius: 0 0 var(--br-mini) var(--br-mini);
    background-color: var(--text-text-white);
    height: 90.8px;
    gap: var(--gap-xs);
  }
  .password2,
  .tabMenuParent,
  .webDepoekle {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;

    justify-content: center;
  }
  .tabMenuParent {
    width: 428px;
    border-radius: var(--br-3xs);
    background-color: var(--text-text-white);
    padding: var(--padding-mini) 0;
    box-sizing: border-box;
    border-radius: 15px;

  }
  .webDepoekle {
    width: 503px;
    max-width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 15px;
    text-align: left;
    font-size: var(--text-md-lineheight-6-font-normal-size);
    color: var(--text-text-black);
    font-family: var(--subhead-2);
  }
  