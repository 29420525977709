.icon {
  width: 25px;
  position: relative;
  height: 35px;
  object-fit: cover;
}
.aliVeli,
.irketAd {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.irketAd {
  font-size: var(--body-3-size);
  line-height: 12px;
  font-weight: 500;
  color: var(--color-dark-grey-v2);
}
.name,
.user {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.user {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}
.div {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}
.number {
  width: 24px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--color-accent-01);
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.addIcon {
  width: 8px;
  position: relative;
  height: 8px;
}
.icon1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xl);
  border: 2px solid var(--neutral-03);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.iconadd {
  display: none;
}
.iconadd,
.uiIconclosefilled {
  cursor: pointer;
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconRightcouple1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.iconRightcouple,
.userParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iconRightcouple {
  justify-content: flex-end;
  text-align: center;
  color: var(--neutral-07);
}
.userParent {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-29xl);
}
.homePageIcon {
  width: 25px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.anaSayfa {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.div1 {
  position: absolute;
  top: 4px;
  left: 6.7px;
  letter-spacing: -0.01em;
  font-weight: 600;
}
.wrapper {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-03);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
  color: var(--text-text-white);
}
.hangarIcon {
  width: 23px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.left1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.container {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-07);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
}
.frame,
.wrapper1 {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.frame {
  background-color: var(--color-accent-01);
  display: none;
}
.wrapper1 {
  background-color: var(--color-accent-04);
}
.iconmenuIcon,
.wrapper2 {
  width: 24px;
  position: relative;
  height: 24px;
}
.wrapper2 {
  width: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-04);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem5 {
  width: 290px;
  border-radius: var(--br-5xs);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-xs);
}
.logoutIcon {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: cover;
}
.navItemParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-dark-grey-v2);
}
.divider,
.navItemParent,
.top {
  align-self: stretch;
}
.top {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.divider {
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--color-dark-grey);
  height: 2px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.2;
}
.dividerParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.image6Icon,
.tr {
  position: relative;
}
.tr {
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.image6Icon {
  width: 30px;
  border-radius: var(--br-9xs);
  height: 20px;
  object-fit: cover;
}
.toggle {
  flex: 1;
  border-radius: var(--br-13xl);
  background-color: var(--text-text-white);
  box-shadow: var(--button-01);
  overflow: hidden;
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
}
.switch,
.toggle,
.toggle1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.toggle1 {
  flex: 1;
  border-radius: var(--br-13xl);
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
}
.switch {
  align-self: stretch;
  border-radius: var(--br-21xl);
  background-color: var(--color-whitesmoke-200);
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
}
.ctaButton,
.navigationDrawer {
  box-sizing: border-box;
  display: flex;
}
.navigationDrawer {
  align-self: stretch;
  width: 300px;
  background-color: var(--text-text-white);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
}
.ctaButton {
  width: 40px;
  border-radius: var(--br-5xs);
  border: 2px solid var(--primary-primary-500);
  height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) 0;
}
.label {
  width: 36px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}
.searchSmIcon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text {
  flex: 1;
  position: relative;
  background-color: var(--text-text-white);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border: none;
  line-height: 24px;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.helpIcon {
  width: 16px;
  position: relative;
  height: 16px;
  display: none;
}
.hintText,
.input {
  color: var(--color-slategray);
}
.input {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--color-lightgray-100);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-sm);
  gap: var(--gap-5xs);
  font-size: var(--text-md-regular-size);
}
.hintText {
  width: 320px;
  position: relative;
  line-height: 20px;
  display: none;
}
.inputField,
.inputFieldBase {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputFieldBase {
  align-self: stretch;
  gap: var(--gap-7xs);
}
.inputField {
  flex: 1;
  overflow: hidden;
}
.vazge {
  position: relative;
  font-size: var(--body-3-size);
  cursor: pointer;
  font-weight: 600;
  font-family: var(--subhead-2);
  color: var(--primary-primary-500);
}
.ctaButtonParent,
.frameWrapper {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.ctaButtonParent {
  background-color: var(--text-text-white);
  height: 56px;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-10xs);
  box-sizing: border-box;
  gap: var(--gap-mini);
}
.frameWrapper {
  flex-direction: column;
  justify-content: center;
  top: -14px;
  position: relative;
}
.image53x1Icon {
  width: 35px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.canKra {
  position: relative;
  line-height: 110%;
}
.aFirmas {
  position: relative;
  font-size: var(--subhead-2-size);
  color: var(--color-lightslategray-100);
}
.canKraParent,
.frameWrapper1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.canKraParent {
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frameWrapper1 {
  flex: 1;
  justify-content: center;
}
.image53x1Parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frameChild {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.phoneIcon {
  width: 22px;
  position: relative;
  height: 22px;
  object-fit: cover;
}
.phoneParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.krckrccorpnet {
  position: relative;
}
.emailSignParent,
.frameParent2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.emailSignParent {
  height: 22px;
  align-items: center;
  gap: var(--gap-5xs);
}
.frameParent2 {
  align-self: stretch;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--gap-mini);
  font-size: var(--body-3-size);
  color: var(--color-lightslategray-100);
}
.notAlannotAlannot {
  flex: 1;
  position: relative;
  line-height: 110%;
  font-weight: 500;
}
.notAlannotAlannotAlanWrapper {
  align-self: stretch;
  border-radius: var(--br-mini);
  background-color: var(--color-deepskyblue);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-8xs);
  font-size: var(--subhead-2-size);
  color: var(--color-darkgray-100);
}
.frameContainer,
.frameParent1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frameParent1 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--text-text-white);
  box-shadow: 2px 12px 20px rgba(90, 117, 167, 0.04);
  justify-content: center;
  padding: var(--padding-xl) var(--padding-base);
  gap: var(--gap-xl);
}
.frameContainer {
  width: 508px;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.recommended1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.07px;
  font-weight: 600;
  display: inline-block;
  height: 19.1px;
  flex-shrink: 0;
}
.industries,
.newWorldFund {
  position: relative;
  letter-spacing: 0.1px;
  font-weight: 600;
}
.industries {
  font-size: var(--body-3-size);
  letter-spacing: 0.09px;
  font-weight: 500;
  color: var(--color-darkslategray-100);
}
.image53x1Group,
.newWorldFundParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.image53x1Group {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-3xs);
}
.midRisk {
  position: relative;
  letter-spacing: 0.09px;
  font-weight: 500;
}
.frameParent4,
.midRiskWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.midRiskWrapper {
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-sandybrown);
  justify-content: center;
  padding: var(--padding-10xs) var(--padding-8xs);
  text-align: center;
  font-size: var(--body-3-size);
  color: var(--color-sandybrown);
}
.frameParent4 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 64px;
}
.div10,
.year1 {
  position: relative;
  letter-spacing: 0.09px;
}
.div10 {
  font-size: var(--font-size-3xs);
  letter-spacing: 0.1px;
  font-weight: 600;
}
.year,
.yearParent {
  display: flex;
  justify-content: center;
}
.year {
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-12xs);
}
.yearParent {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--gap-mini);
}
.div14,
.year8,
.yearParent {
  align-self: stretch;
}
.div14 {
  position: relative;
  font-size: var(--font-size-3xs);
  letter-spacing: 0.1px;
  font-weight: 600;
}
.year8 {
  overflow: hidden;
  justify-content: center;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-12xs);
}
.frameParent3,
.frameParent5,
.year8 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frameParent5 {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-sm);
  font-size: var(--body-3-size);
  color: var(--color-dimgray-100);
}
.frameParent3 {
  flex: 1;
  justify-content: center;
  gap: var(--gap-6xl);
  z-index: 0;
}
.categories {
  width: 19.26%;
  position: absolute;
  margin: 0 !important;
  height: 4.82%;
  top: 10.14%;
  right: 68.53%;
  bottom: 85.04%;
  left: 12.21%;
  overflow: hidden;
  z-index: 1;
}
.funds1 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-8xs);
  position: relative;
  font-size: var(--subhead-2-size);
  color: var(--color-gray-100);
}
.recommended {
  width: 571px;
  border-radius: var(--br-mini);
  background-color: var(--text-text-white);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  gap: var(--gap-8xs);
  font-size: var(--base-1-semibold-size);
  color: var(--color-dimgray-100);
  font-family: var(--text-md-regular);
}
.backpackRidd,
.markaId {
  align-self: stretch;
  position: relative;
}
.backpackRidd {
  letter-spacing: 0.2px;
  font-weight: 500;
}
.markaId {
  font-size: var(--body-3-size);
  font-family: var(--text-md-regular);
  color: var(--color-dimgray-100);
}
.backpackRiddParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
}
.div15 {
  position: relative;
  letter-spacing: 0.1px;
  font-weight: 500;
  font-family: var(--text-md-regular);
  color: var(--color-seagreen);
  text-align: right;
}
.frameParent7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.div16,
.id1 {
  position: relative;
}
.id1 {
  font-weight: 600;
}
.div16 {
  font-size: var(--subhead-2-size);
  letter-spacing: 0.2px;
  color: var(--color-darkslategray-100);
  text-align: left;
}
.id {
  overflow: hidden;
  padding: 0 var(--padding-12xs);
}
.id,
.idParent,
.stock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-8xs);
}
.stock {
  overflow: hidden;
}
.idParent {
  align-self: stretch;
  flex-wrap: wrap;
  text-align: center;
  font-size: var(--font-size-2xs);
  color: var(--color-dimgray-100);
  font-family: var(--text-md-regular);
}
.frameParent6 {
  width: 641px;
  border-radius: var(--br-3xs);
  background-color: var(--text-text-white);
  border: 1px solid var(--color-seagreen);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) var(--padding-8xs);
  gap: var(--gap-3xl);
  font-size: var(--subhead-2-size);
  color: var(--color-gray-100);
}
.frameGroup,
.frameParent {
  align-self: stretch;
  display: flex;
  gap: var(--gap-mini);
}
.frameGroup {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  font-size: var(--text-md-regular-size);
  color: var(--color-gray-400);
  font-family: var(--font-poppins);
}
.frameParent {
  flex: 1;
  border-radius: var(--br-mini);
  background-color: var(--text-text-white);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-mini);
  font-size: var(--subhead-2-size);
  color: var(--color-darkslategray-200);
}
.aramaSayfasi {
  width: 100%;
  position: relative;
  background-color: var(--color-gray-200);
  height: "100%";
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--base-1-semibold-size);
  color: var(--color-light-body-text);
  font-family: var(--text-md-regular);
}
