.icon {
  width: 25px;
  position: relative;
  height: 35px;
  object-fit: cover;
}
.aliVeli,
.irketAd {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.irketAd {
  font-size: var(--subhead-3-size);
  line-height: 12px;
  font-weight: 500;
  color: var(--color-dark-grey-v2);
}
.name,
.user {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.user {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}
.div {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}
.number {
  width: 24px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--color-accent-01);
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.addIcon {
  width: 8px;
  position: relative;
  height: 8px;
}
.icon1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xl);
  border: 2px solid var(--neutral-03);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.iconadd {
  display: none;
}
.iconadd,
.uiIconclosefilled {
  cursor: pointer;
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconRightcouple1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.iconRightcouple,
.userParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iconRightcouple {
  justify-content: flex-end;
  text-align: center;
  color: var(--neutral-07);
}
.userParent {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-29xl);
}
.homePageIcon {
  width: 25px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.anaSayfa {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.div1 {
  position: absolute;
  top: 4px;
  left: 6.7px;
  letter-spacing: -0.01em;
  font-weight: 600;
}
.wrapper {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-03);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
  color: var(--text-text-white);
}
.hangarIcon {
  width: 23px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.container {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-07);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
}
.frame,
.wrapper1 {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.frame {
  background-color: var(--color-accent-01);
  display: none;
}
.wrapper1 {
  background-color: var(--color-accent-04);
}
.iconmenuIcon,
.wrapper2 {
  width: 24px;
  position: relative;
  height: 24px;
}
.wrapper2 {
  width: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-04);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem5 {
  width: 240px;
  border-radius: var(--br-5xs);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-xs);
}
.logoutIcon {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: cover;
}
.navItemParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-dark-grey-v2);
}
.divider,
.navItemParent,
.top {
  align-self: stretch;
}
.top {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.divider {
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--color-dark-grey);
  height: 2px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.2;
}
.dividerParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.image6Icon,
.tr {
  position: relative;
}
.tr {
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.image6Icon {
  width: 30px;
  border-radius: var(--br-9xs);
  height: 20px;
  object-fit: cover;
}
.toggle {
  background-color: var(--text-text-white);
  box-shadow: var(--button-01);
  overflow: hidden;
  gap: var(--gap-5xs);
}
.toggle,
.toggle1 {
  flex: 1;
  border-radius: var(--br-13xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
}
.navigationDrawer,
.switch {
  align-self: stretch;
  display: flex;
}
.switch {
  border-radius: var(--br-21xl);
  background-color: var(--color-whitesmoke-200);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
}
.navigationDrawer {
  width: 300px;
  background-color: var(--text-text-white);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  box-sizing: border-box;
}
.ctaButton,
.header1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ctaButton {
  width: 40px;
  cursor: pointer;
  border-radius: var(--br-5xs);
  border: 2px solid var(--primary-primary-500);
  box-sizing: border-box;
  height: 40px;
  justify-content: center;
  padding: var(--padding-xs) 0;
}
.header1 {
  align-self: stretch;
  background-color: #F5F5F5;
  justify-content: flex-start;
  padding: var(--padding-8xs) var(--padding-6xl);
  gap: var(--gap-base);
}
.recommended1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.07px;
  font-weight: 600;
}
.industries,
.newWorldFund {
  position: relative;
  letter-spacing: 0.1px;
  font-weight: 600;
}
.industries {
  font-size: var(--subhead-3-size);
  letter-spacing: 0.09px;
  font-weight: 500;
  color: var(--color-darkslategray-100);
}
.frameWrapper,
.newWorldFundParent {
  display: flex;
  justify-content: flex-start;
}
.newWorldFundParent {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
  gap: var(--gap-11xs);
}
.frameWrapper {
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.shape {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-sandybrown);
  box-sizing: border-box;
  opacity: 0.25;
  mix-blend-mode: normal;
}
.midRisk1 {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 37px);
  color: var(--color-sandybrown);

  letter-spacing: 0.09px;
  font-weight: 500;
}
.midRisk1iptal {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 37px);
  color: var(--color-crimson);

  letter-spacing: 0.09px;
  font-weight: 500;
}
.midRisk1ok {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 37px);
  color: var(--color-forestgreen);

  letter-spacing: 0.09px;
  font-weight: 500;
}
.midRisk1new {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 37px);
  color: var(--color-khaki);

  letter-spacing: 0.09px;
  font-weight: 500;
}
.midRisk {
  width: 128px;
  position: relative;
  height: 30px;
  overflow: hidden;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--subhead-3-size);
}
.frameGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.div9,
.year1 {
  position: relative;
  letter-spacing: 0.09px;
}
.div9 {
  font-size: var(--font-size-3xs);
  letter-spacing: 0.1px;
  font-weight: 600;
}
.year,
.yearParent {
  display: flex;
  justify-content: center;
}
.year {
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-12xs);
}
.yearParent {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--gap-mini);
}
.year9 {
  position: relative;
  letter-spacing: 0.09px;
}
.div13,
.year8,
.year9 {
  align-self: stretch;
}
.div13 {
  position: relative;
  font-size: var(--font-size-3xs);
  letter-spacing: 0.1px;
  font-weight: 600;
  text-align: left;
}
.year8 {
  overflow: hidden;
  justify-content: center;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-12xs);
  text-align: center;
}
.frameContainer,
.frameParent,
.year8 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frameContainer {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-sm);
  font-size: var(--subhead-3-size);
  color: var(--color-dimgray-100);
}
.frameParent {
  flex: 1;
  justify-content: center;
  padding: 0 var(--padding-8xs);
  gap: var(--gap-mini);
  z-index: 0;
}
.separator {
  width: 69.8%;
  position: absolute;
  margin: 0 !important;
  height: 0.23%;
  top: 18.55%;
  right: 28.97%;
  bottom: 81.22%;
  left: 1.24%;
  background-color: var(--color-whitesmoke-100);
  z-index: 1;
}
.funds1,
.header,
.recommended {
  display: flex;
  justify-content: center;
}
.funds1 {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  text-align: left;
  font-size: var(--subhead-2-size);
  color: var(--color-gray-100);
}
.header,
.recommended {
  flex-direction: column;
  align-items: center;
}
.recommended {
  width: 495px;
  border-radius: var(--br-mini);
  background-color: var(--text-text-white);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  padding: var(--padding-3xs) 0;
  gap: 17px;
  font-size: var(--base-1-semibold-size);
  color: var(--color-dimgray-100);
  font-family: var(--text-md-regular);
}
.header {
  align-self: stretch;
  gap: var(--gap-mini);
}
.div14,
.durumGncellemeleri {
  position: relative;
}
.div14 {
  font-size: var(--subhead-3-size);
  font-weight: 600;
  color: var(--grayscale-gray-light-gray);
}
.left9,
.placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left9 {
  flex: 1;
  gap: var(--gap-5xs);
}
.placeholder {
  align-self: stretch;
}
.dividerIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.jan092024,
.sonGncelleme {
  position: relative;
  font-weight: 600;
}
.jan092024 {
  line-height: 18px;
  font-weight: 500;
  color: var(--grayscale-gray-light-gray);
}
.timeUploaded {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--text-text-black);
}
.badges,
.label {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.badges {
  align-self: stretch;
  width: 90px;
  border-radius: var(--br-6xs) 0 0 var(--br-6xs);
  background-color: var(--color-sandybrown);
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.tarih {
  position: relative;
  line-height: 18px;
  font-weight: 500;
}
.bottom1,
.date,
.left10 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.bottom1,
.left10 {
  justify-content: center;
}
.left10 {
  align-self: stretch;
}
.canKra {
  position: relative;
  font-weight: 600;
  color: var(--text-text-black);
}
.leftParent,
.menu {
  display: flex;
  align-items: flex-start;
}
.leftParent {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.menu {
  height: 63px;
  flex-direction: row;
  justify-content: center;
}
.removeIcon {
  width: 40px;
  visibility: hidden;
  position: relative;
  border-radius: var(--br-11xs);
  height: 40px;
  object-fit: cover;
}
.right1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-xl);
}
.container3,
.right {
  align-self: stretch;
  display: flex;
}
.container3 {
  height: 107px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs) 0 var(--padding-xs) var(--padding-31xl);
  box-sizing: border-box;
}
.right {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.container2,
.task01 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.container2 {
  flex: 1;
  border-left: 1px solid var(--grayscale-white-dark-white);
  color: var(--grayscale-gray-light-gray);
}
.task01 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  cursor: pointer;
  box-shadow: var(--container-light);
  border: 1px solid var(--grayscale-white-dark-white);
  padding: var(--padding-12xs);
}
.badges1 {
  width: 90px;
  border-radius: var(--br-6xs) 0 0 var(--br-6xs);
  background-color: var(--color-khaki);
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.badges1,
.badges2,
.badges3,
.task3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
}
.task3 {
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  box-shadow: var(--container-light);
  border: 1px solid var(--grayscale-white-dark-white);
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs);
  color: var(--color-darkgoldenrod);
}
.badges2,
.badges3 {
  width: 90px;
  border-radius: var(--br-6xs) 0 0 var(--br-6xs);
  background-color: var(--color-forestgreen);
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.badges3 {
  background-color: var(--error-error-500);
}
.container1,
.onProgress {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.container1 {
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--subhead-3-size);
  color: var(--text-text-white);
}
.onProgress {
  border-radius: var(--br-5xs);
  box-shadow: var(--container-light);
  border: 1px solid var(--grayscale-white-dark-white);
  justify-content: center;
  cursor: pointer;
  padding: var(--padding-base);
  gap: var(--gap-5xl);
}
.left19 {
  align-self: stretch;
  height: 33px;
}
.checkAllIcon {
  width: 30px;
  position: relative;
  height: 30px;
  object-fit: cover;
}
.container12,
.right8 {
  align-self: stretch;
  display: flex;
}
.container12 {
  height: 107px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs) 0 var(--padding-xs) var(--padding-31xl);
  box-sizing: border-box;
}
.right8 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.container11 {
  flex: 1;
  border-left: 1px solid var(--grayscale-white-dark-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--subhead-2-size);
  color: var(--text-text-black);
}
.done,
.onProgressKapali {
  align-self: stretch;
  border-radius: var(--br-5xs);
  box-shadow: var(--container-light);
  border: 1px solid var(--grayscale-white-dark-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  cursor: pointer;
  justify-content: center;
  padding: var(--padding-base);
}
.done {
  padding: var(--padding-xs) var(--padding-base);
}
.placeholder5,
.timeUploaded2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--heading-3-size);
}
.timeUploaded2 {
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--subhead-3-size);
  color: var(--text-text-black);
}
.maleUserIcon {
  width: 51px;
  position: relative;
  height: 45px;
  object-fit: cover;
}
.yolda {
  color: var(--color-limegreen-100);
}
.container20,
.right16 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.container20 {
  width: 155px;
  height: 45px;
  flex-direction: column;
  padding: var(--padding-11xs) 0;
  box-sizing: border-box;
}
.right16 {
  align-self: stretch;
  flex-direction: row;
}
.durumuAlndOlarak {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-weight: 600;
}
.container21 {
  align-self: stretch;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs) 0;
  box-sizing: border-box;
  color: var(--color-dimgray-200);
}
.rightParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.container19,
.task31 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.task31 {
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  box-shadow: var(--container-light);
  border: 1px solid var(--grayscale-white-dark-white);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-12xs);
}
.container19 {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 150px;
  gap: var(--gap-xs);
  color: var(--color-gray-300);
}
.notBraknNot {
  flex: 1;
  position: relative;
  border: none;
  outline: none;
  border: none;
  font-weight: 600;
  background-color: #f5f5f5;

}
.notBraknNotBraknNoWrapper {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-6xl) var(--padding-10xs);
}
.xlLabel {
  position: relative;
  font-weight: 800;
}
.ctaButton1 {
  width: 383px;
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
  height: 39px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) 0;
  box-sizing: border-box;
  font-size: var(--heading-3-size);
  color: var(--text-text-white);
}
.body,
.frameParent1,
.yeniDurumAcik1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.frameParent1 {
  align-items: center;
  padding: 0 250px;
  gap: var(--gap-mini);
  color: #969696;
}
.body,
.yeniDurumAcik1 {
  gap: var(--gap-5xl);
}
.yeniDurumAcik1 {
  border-radius: var(--br-5xs);
  box-shadow: var(--container-light);
  border: 1px solid var(--grayscale-white-dark-white);
  align-items: flex-start;
  padding: var(--padding-base);
  font-size: var(--font-size-3xs);
}
.body {
  align-items: center;
  padding: 0 var(--padding-81xl);
  text-align: left;
  font-size: var(--heading-3-size);
}
.durumGncelleme,
.headerParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.headerParent {
  flex: 1;
  flex-direction: column;
  gap: 44px;
  text-align: center;
  font-size: var(--heading-2-size);
  color: var(--text-text-black);
  font-family: var(--heading-3);
}
.durumGncelleme {
  width: 100%;
  position: relative;
  background-color: var(--color-gray-200);
  overflow: hidden;
  flex-direction: row;
  text-align: left;
  font-size: var(--base-1-semibold-size);
  color: var(--color-light-body-text);
  font-family: var(--text-md-regular);
}
