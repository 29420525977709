.dk011Icon,
.groupChild {
  position: absolute;
  top: 0;
  left: 29px;
  width: 1219.8px;
  height: 608.3px;
}
.groupChild {
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--primary-primary-500);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 626px;
  height: 722.3px;
}
.t11Icon {
  width: 175px;
  height: 118px;
  object-fit: cover;
}
.qstock,
.t11Icon {
  position: relative;
}
.t11Parent {
  position: absolute;
  top: 0;
  left: calc(50% - 175px);
  border-radius: var(--br-3xs);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.logo {
  position: absolute;
  top: 0.3px;
  left: calc(50% - 175.5px);
  width: 350px;
  height: 118px;
  font-size: 50px;
  color: var(--grayscale-white-almost-white);
}
.kayitChild {
  position: absolute;
  top: 3px;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-whitesmoke-400);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  width: 441.3px;
  height: 565.2px;
}
.kaytOl {
  position: relative;
  font-size: var(--font-size-21xl);
}
.inputUsername,
.sim {
  position: absolute;
  top: 0;
}
.sim {
  left: 4.3px;
  display: inline-block;
  width: 86.6px;
  height: 15.4px;
}
.inputUsername {
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-500);
  width: 360.1px;
  height: 39.7px;
}
.sim1 {
  position: absolute;
  top: calc(50% - 7.65px);
  left: 14.6px;
  display: inline-block;
  width: 86.6px;
  height: 15.4px;
}
.groupItem,
.inputUsernameParent {
  position: absolute;
  top: 21.1px;
  left: 0;
  width: 360.1px;
  height: 39.7px;
}
.groupItem {
  top: 0;
  border-radius: var(--br-6xs);
  background-color: var(--primary-primary-500);
  width: 52px;
}
.personIcon {
  position: absolute;
  top: calc(50% - 17.65px);
  left: calc(50% - 17.4px);
  width: 35px;
  height: 35px;
  object-fit: cover;
}
.rectangleGroup {
  position: absolute;
  top: 21.1px;
  left: 307.9px;
  width: 52px;
  height: 39.7px;
}
.firma,
.simParent {
  width: 360.1px;
  position: relative;
  height: 60.8px;
}
.firma {
  width: 86.6px;
  display: inline-block;
  height: 15.4px;
  flex-shrink: 0;
}
.inputUsernameGroup {
  width: 360.1px;
  position: relative;
  height: 39.7px;
}
.firmaParent {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.companyIcon {
  position: absolute;
  top: calc(50% - 17.45px);
  left: calc(50% - 17.4px);
  width: 35px;
  height: 35px;
  object-fit: cover;
}
.frameParent {
  width: 360.1px;
  position: relative;
  height: 61.1px;
}
.email {
  position: absolute;
  top: calc(50% - 7.35px);
  left: 14.6px;
  display: inline-block;
  width: 86.6px;
  height: 15.4px;
}
.letterIcon {
  position: absolute;
  top: 8.1px;
  left: 10.2px;
  width: 30.6px;
  height: 24.3px;
  object-fit: cover;
}
.inputPasswordParent,
.rectangleParent2 {
  position: absolute;
  top: 20.3px;
  left: 0;
  width: 360.1px;
  height: 39.7px;
}
.rectangleParent2 {
  left: 307.9px;
  width: 52px;
}
.groupParent1 {
  width: 360.1px;
  position: relative;
  height: 60px;
}
.groupContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
  text-align: left;
  color: var(--color-black);
  font-family: var(--font-inria-sans);
}
.inputPassword1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-6xs);
  background-color: var(--primary-primary-500);
  width: 360.1px;
  height: 39.7px;
}
.kaytOl1 {
  position: absolute;
  top: 12.2px;
  left: calc(50% - 44.25px);
  display: inline-block;
  width: 88.1px;
  height: 15.4px;
}
.buttonLogin {
  width: 360.1px;
  position: relative;
  height: 39.7px;
  color: var(--text-text-white);
}
.frameChild {
  width: 128.8px;
  position: relative;
  border-top: 1px solid var(--color-darkgray-200);
  box-sizing: border-box;
  height: 1px;
}
.veya {
  width: 31px;
  position: relative;
  display: inline-block;
  height: 18px;
  flex-shrink: 0;
}
.lineParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-14xl);
  text-align: left;
  font-size: var(--text-middle-15px-bold-size);
  color: var(--color-darkgray-200);
  font-family: var(--font-inria-sans);
}
.inputPassword2 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-6xs);
  background-color: var(--text-text-white);
  border: 1px solid var(--color-darkslategray-300);
  box-sizing: border-box;
  width: 360.1px;
  height: 39.7px;
}
.girisYap {
  position: absolute;
  top: 12.2px;
  left: calc(50% - 49.45px);
  display: inline-block;
  width: 99.1px;
  height: 15.4px;
}
.buttonSignup {
  width: 360.1px;
  position: relative;
  height: 39.7px;
  color: var(--color-darkslategray-300);
}
.kaytOlParent {
  position: absolute;
  top: calc(50% - 284.1px);
  left: calc(50% - 186.25px);
  width: 372.3px;
  height: 531.8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px 15px;
}
.giris,
.kayit {
  text-align: center;
}
.kayit {
  position: absolute;
  top: 118.3px;
  left: 91.9px;
  width: 441.3px;
  height: 568.2px;
  display: none;
  color: var(--primary-primary-500);
}
.giris {
  width: 116.7px;
  position: relative;
  font-size: var(--font-size-21xl);
  display: inline-block;
  height: 38.9px;
  flex-shrink: 0;
}
.email2,
.email3 {
  display: inline-block;
  width: 86.6px;
  height: 15.4px;
}
.email2 {
  position: relative;
  flex-shrink: 0;
  z-index: 0;
}
.email3 {
  position: absolute;
  top: calc(50% - 7.75px);
  left: 15px;
}
.rectangleParent4 {
  width: 52px;
  position: absolute;
  margin: 0 !important;
  top: 21.1px;
  left: 307.9px;
  height: 39.7px;
  z-index: 2;
}
.emailParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-7xs);
  color: var(--color-black);
  font-family: var(--font-inria-sans);
}
.ifre3 {
  position: absolute;
  top: calc(50% - 7.95px);
  left: 15px;
  display: inline-block;
  width: 86.6px;
  height: 15.4px;
}
.inputPasswordGroup {
  width: 360.1px;
  position: relative;
  height: 39.7px;
  z-index: 1;
}
.rectangleParent5 {
  width: 52px;
  position: absolute;
  margin: 0 !important;
  top: 20.3px;
  left: 307.9px;
  height: 39.7px;
  z-index: 2;
}
.ifreParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-8xs);
  color: var(--color-black);
  font-family: var(--font-inria-sans);
}
.buttonLogin1 {
  width: 360.1px;
  position: relative;
  height: 39.7px;
  text-align: center;
  color: var(--text-text-white);
}
.qrGiriYap {
  position: relative;
  font-weight: 800;
}
.label {
  justify-content: center;
}
.ctaButton,
.label,
.lineGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ctaButton {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--color-black);
  height: 42px;
  justify-content: center;
  padding: var(--padding-xs) 0;
  box-sizing: border-box;
  font-size: var(--text-lg-lineheight-7-font-semibold-size);
  color: var(--text-text-white);
  font-family: var(--subhead-2);
}
.lineGroup {
  justify-content: flex-start;
  gap: var(--gap-14xl);
  font-size: var(--text-middle-15px-bold-size);
  color: var(--color-darkgray-200);
  font-family: var(--font-inria-sans);
}
.buttonSignup1 {
  width: 360.1px;
  position: relative;
  height: 39.7px;
  text-align: center;
}
.girisParent {
  position: absolute;
  top: 19.1px;
  left: calc(50% - 180.5px);
  border-radius: var(--br-3xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 29px;
}
.qrgirisChild,
.rectangleParent3 {
  position: absolute;
  width: 442px;
  height: 523px;
}
.rectangleParent3 {
  top: 143.3px;
  left: 92.5px;
  display: none;
}
.qrgirisChild {
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-400);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}
.image9Icon {
  width: 288px;
  position: relative;
  height: 296px;
  object-fit: cover;
}
.image9Parent,
.lineContainer,
.mobilUygulamadakiQrGiriWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.image9Parent,
.lineContainer {
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-8xs);
  text-align: left;
  color: var(--color-darkgray-200);
  font-family: var(--font-inria-sans);
}
.lineContainer {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-14xl);
}
.girisYap2 {
  position: absolute;
  top: 7.2px;
  left: calc(50% - 41.55px);
}
.buttonSignup2 {
  width: 360.1px;
  position: relative;
  height: 39.7px;
  font-size: var(--heading-2-size);
}
.qrGirisParent {
  position: absolute;
  top: 19.1px;
  left: calc(50% - 191.9px);
  border-radius: var(--br-3xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-mini);
}
.qrgiris {
  position: absolute;
  top: 143.3px;
  left: 92.5px;
  width: 442px;
  height: 523px;
  text-align: center;
  font-size: var(--text-middle-15px-bold-size);
}
.rectangleParent {
  align-self: stretch;
  width: 626px;
  position: relative;
}
.image5Icon {
  width: 545px;
  position: relative;
  height: 381px;
  object-fit: cover;
}
.groupParent,
.image5Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.image5Wrapper {
  width: 626px;
  height: 683px;
  padding: var(--padding-31xl);
  box-sizing: border-box;
}
.groupParent {
  position: absolute;
  top: calc(50% - 360.3px);
  left: calc(50% - 743.5px);
  width: 1487px;
  height: 722.3px;
  gap: 28px;
}
.dk011Parent {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.yeniGiris {
  width: 100%;
  position: relative;
  background-color: var(--grayscale-white-almost-white);
  height: 724px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--text-md-lineheight-6-font-normal-size);
  color: var(--color-darkslategray-300);
  font-family: var(--font-fredoka-one);
}
