.icon {
  width: 25px;
  position: relative;
  height: 35px;
  object-fit: cover;
}
.aliVeli,
.irketAd {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.irketAd {
  font-size: var(--body-3-size);
  line-height: 12px;
  font-weight: 500;
  color: var(--color-dark-grey-v2);
}
.name,
.user {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.user {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}
.div {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}
.number {
  width: 24px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--color-accent-01);
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.addIcon {
  width: 8px;
  position: relative;
  height: 8px;
}
.icon1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xl);
  border: 2px solid var(--neutral-03);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.iconadd {
  display: none;
}
.iconadd,
.uiIconclosefilled {
  cursor: pointer;
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconRightcouple1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.iconRightcouple,
.userParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iconRightcouple {
  justify-content: flex-end;
  text-align: center;
  color: var(--neutral-07);
}
.userParent {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-29xl);
}
.homePageIcon {
  width: 25px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.anaSayfa {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.div1 {
  position: absolute;
  top: 4px;
  left: 6.7px;
  letter-spacing: -0.01em;
  font-weight: 600;
}
.wrapper {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-03);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
  color: var(--text-text-white);
}
.hangarIcon {
  width: 23px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.left1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.container {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-07);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
}
.frame,
.wrapper1 {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.frame {
  background-color: var(--color-accent-01);
  display: none;
}
.wrapper1 {
  background-color: var(--color-accent-04);
}
.iconmenuIcon,
.wrapper2 {
  width: 24px;
  position: relative;
  height: 24px;
}
.wrapper2 {
  width: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-04);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem5 {
  width: 290px;
  border-radius: var(--br-5xs);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-xs);
}
.logoutIcon {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: cover;
}
.navItemParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-dark-grey-v2);
}
.divider,
.navItemParent,
.top {
  align-self: stretch;
}
.top {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.divider {
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--color-dark-grey);
  height: 2px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.2;
}
.dividerParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.image6Icon,
.tr {
  position: relative;
}
.tr {
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.image6Icon {
  width: 30px;
  border-radius: var(--br-9xs);
  height: 20px;
  object-fit: cover;
}
.toggle {
  flex: 1;
  border-radius: var(--br-13xl);
  background-color: var(--text-text-white);
  box-shadow: var(--button-01);
  overflow: hidden;
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
}
.switch,
.toggle,
.toggle1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.toggle1 {
  flex: 1;
  border-radius: var(--br-13xl);
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
}
.switch {
  align-self: stretch;
  border-radius: var(--br-21xl);
  background-color: var(--color-whitesmoke-200);
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
}
.navigationDrawer {
  width: 300px;
  background-color: var(--text-text-white);
  height: 1347.8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  box-sizing: border-box;
}
.label {
  width: 36px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}
.searchSmIcon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.helpIcon {
  width: 16px;
  position: relative;
  height: 16px;
  display: none;
}
.hintText,
.input {
  color: var(--color-slategray);
}
.input {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--color-lightgray-100);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-sm);
  gap: var(--gap-5xs);
  font-size: var(--text-md-regular-size);
}
.hintText {
  width: 320px;
  position: relative;
  line-height: 20px;
  display: none;
}
.inputField,
.inputFieldBase {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputFieldBase {
  align-self: stretch;
  gap: 6px 0;
}
.inputField {
  flex: 1;
  overflow: hidden;
}
.bell02Icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.bellChild {
  width: 8px;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 14.5px;
  border-radius: 50%;
  background-color: var(--color-crimson);
  height: 8px;
  z-index: 1;
}
.bell,
.menu01Parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.bell {
  align-items: flex-start;
  position: relative;
  gap: var(--gap-5xs);
}
.menu01Parent {
  align-self: stretch;
  background-color: #F5F5F5;
  align-items: center;
  padding: var(--padding-5xs) var(--padding-base);
  gap: 0 16px;
}
.shape {
  border-radius: 6.32px;
  background-color: var(--color-mediumslateblue);
}
.btnSend,
.shape {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.componenticonicPlus {
  position: absolute;
  height: 66.6%;
  width: 66.6%;
  top: 16.6%;
  right: 16.8%;
  bottom: 16.8%;
  left: 16.6%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.add {
  width: 35px;
  cursor: pointer;
  position: relative;
  margin-left: 35px;
  height: 35px;
}
.productsList {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.smallLabel {
  position: relative;
  border: none;
  outline: none;

  font-weight: 600;

}
.container1,
.label1,
.placeholder {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.label1 {
  width: 361px;
  display: none;
}
.container1,
.placeholder {
  align-self: stretch;
  display: flex;
}
.container1 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--primary-primary-500);
  box-sizing: border-box;
  height: 35px;
  flex-direction: column;
  cursor: pointer;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xs);
  color: var(--grayscale-white-dark-white);
}
.optionA {
  background-color: var(--grayscale-white-almost-white);
}
.optionA,
.optionA1 {
  align-self: stretch;
  border-radius: var(--br-3xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
}
.dropdownMenu,
.priorityLevelDropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.dropdownMenu {
  align-self: stretch;
  border-radius: var(--br-3xs);
  border: 1px solid var(--grayscale-white-shaded-white);
  padding: var(--padding-12xs);
  cursor: pointer;
}
.priorityLevelDropdown {
  width: 490px;
  padding: 0 var(--padding-mini);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  font-size: var(--body-3-size);
  color: var(--text-text-black);
  font-family: var(--subhead-2);
}
.image8Icon {
  width: 60px;
  position: relative;
  height: 45px;
  object-fit: cover;
}
.backpackRidd {
  position: relative;
  letter-spacing: 0.2px;
  font-weight: 500;
}


.productsList1 {
  position: relative;
  letter-spacing: 0.2px;
  font-weight: 600;
  font-size: 24px;
}

.markaId {
  position: relative;
  font-size: var(--body-3-size);
  font-family: var(--text-md-regular);
  color: var(--color-dimgray-100);
}
.backpackRiddParent,
.image8Parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
}
.image8Parent {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-6xl);
}
.midRisk1 {
  position: relative;
  letter-spacing: 0.09px;
  font-weight: 500;
}
.frameParent1,
.midRisk {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.midRisk {
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-limegreen);
  overflow: hidden;
  justify-content: center;
  padding: 7px var(--padding-9xs);
  text-align: center;
  font-size: 14px;
  color: var(--color-limegreen);
  font-family: var(--text-md-regular);
}

.midRisky {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-red);
  overflow: hidden;
  justify-content: center;
  padding: 7px var(--padding-9xs);
  text-align: center;
  font-size: 14px;
  color: var(--color-red);
  font-family: var(--text-md-regular);
}
.frameParent1 {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.div9 {
  position: relative;
  font-size: var(--subhead-2-size);
  letter-spacing: 0.2px;
  color: var(--color-darkslategray-100);
  text-align: left;
}
.id {
  overflow: hidden;
  justify-content: center;
  padding: 0 var(--padding-12xs);
}
.id,
.idParent,
.var {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-8xs);
}
.var {
  overflow: hidden;
  justify-content: center;
}
.idParent {
  align-self: stretch;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-2xs);
  color: var(--color-dimgray-100);
  font-family: var(--text-md-regular);
}
.frameContainer,
.frameWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.frameContainer {
  align-items: center;
  padding: 0 var(--padding-mini);
  gap: var(--gap-3xs);
}
.frameWrapper {
  border-radius: var(--br-mini);
  background-color: var(--text-text-white);
  margin-left: 35px;
  cursor: pointer;
  align-items: flex-start;
  padding: var(--padding-6xl) 0;
}
.shape1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 100%;
  right: 0;
  bottom: -100%;
  left: 0;
  border-radius: var(--br-5xs);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
}
.icChevronLeftIcon {
  position: absolute;
  height: 74.83%;
  width: 75%;
  top: 12.42%;
  right: 12.5%;
  bottom: 12.75%;
  left: 12.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.btnChevronLeft {
  width: 32px;
  position: relative;
  height: 29.8px;
}
.shape2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 100%;
  right: -100%;
  bottom: -100%;
  left: 100%;
  border-radius: var(--br-5xs);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  transform: rotate(-180deg);
  transform-origin: 0 0;
}
.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-mini) 0;
  gap: var(--gap-mini);
}
.frameGroup,
.frameParent,
.productsListParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frameGroup {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 var(--padding-81xl);
  gap: var(--gap-3xs);
  margin-right: 50px;
  font-size: var(--subhead-2-size);
  font-family: var(--font-poppins);
}
.frameParent,
.productsListParent {
  justify-content: center;
}
.productsListParent {
  align-self: stretch;
  padding: 0 var(--padding-31xl);
  gap: 75px;
  justify-content: center;

  width: 850px;
  font-size: var(--heading-3-size);
  color: var(--color-gray-100);
}
.frameParent {
  flex: 1;
  padding: 0 0 var(--padding-731xl);
  gap: 27px;
  font-size: var(--subhead-2-size);
  color: var(--color-darkslategray-200);
}

.navigationDrawerParent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 0;
}
.ctaButton {
  width: 40px;
  border-radius: var(--br-5xs);
  border: 2px solid var(--primary-primary-500);
  box-sizing: border-box;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) 0;
}
.rnEkle {
  position: relative;
}
.header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.container2,
.taskName {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.container2 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  border: 1px solid var(--grayscale-white-dark-white);
  box-sizing: border-box;
  height: 35px;
  align-items: flex-start;
  padding: var(--padding-xs);
  color: var(--grayscale-white-dark-white);
}
.taskName {
  width: 305px;
  align-items: center;
  gap: var(--gap-5xs);
}
.optionA2 {
  background-color: whitesmoke;
}
.optionA2,
.optionA3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
}
.optionA6,
.priorityLevelDropdown1 {
  display: flex;
  justify-content: center;
}
.optionA6 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xs);
  color: var(--color-mediumslateblue);
}
.priorityLevelDropdown1 {
  width: 281px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.taskNameParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.priorityLevelDropdown2 {
  width: 331px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.barcodeReaderParent,
.container5,
.priorityLevelDropdown2 {
  display: flex;
  justify-content: center;
}
.container5 {
  flex: 1;
  border-radius: var(--br-5xs);
  border: 1px solid var(--grayscale-white-dark-white);
  box-sizing: border-box;
  height: 35px;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-xs);
}
.barcodeReaderParent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-8xs);
}
.containerParent,
.taskName1 {
  display: flex;
  align-items: center;
  gap: var(--gap-5xs);
}
.containerParent {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  color: var(--grayscale-white-dark-white);
}
.taskName1 {
  width: 360px;
  flex-direction: column;
  justify-content: center;
}
.priorityLevelDropdownParent {
  flex-direction: column;
  margin-left: 15px;
  gap: var(--gap-mini);
}
.frameParent3,
.priorityLevelDropdown3,
.priorityLevelDropdownParent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.frameParent3 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-31xl);
}
.priorityLevelDropdown3 {
  width: 373px;
  flex-direction: column;
  gap: var(--gap-5xs);
}
.priorityLevelDropdownGroup,
.taskName2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.taskName2 {
  width: 268px;
  flex-direction: column;
  gap: var(--gap-5xs);
}
.priorityLevelDropdownGroup {
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-6xl);
}
.aklamaEkleaklamaEkleak {
  flex: 1;
  border: none;
  background-color: whitesmoke;
  outline: none;
  position: relative;
  font-weight: 600;
}
.container8,
.taskName3 {
  flex-direction: column;
  justify-content: center;
}
.container8 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  border: 1px solid var(--grayscale-white-dark-white);
  display: flex;
  align-items: flex-start;
  padding: var(--padding-xs);
  color: var(--grayscale-white-dark-white);
}
.taskName3 {
  width: 466px;
  gap: var(--gap-5xs);
}
.container9,
.middle,
.taskName3 {
  display: flex;
  align-items: center;
}
.middle {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.container9 {
  width: 89.3px;
  border-radius: var(--br-5xs);
  box-shadow: var(--container-light);
  border: 1px solid var(--grayscale-white-dark-white);
  box-sizing: border-box;
  height: 71px;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-xs);
}
.image1Icon {
  width: 40px;
  position: relative;
  height: 43px;
  object-fit: cover;
}
.containerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
  color: var(--grayscale-gray-light-gray);
}
.xlLabel {
  position: relative;
  font-weight: 800;
}
.ctaButton1,
.label9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ctaButton1 {
  width: 397px;
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
  height: 55px;
  padding: var(--padding-xs) 0;
  box-sizing: border-box;
  font-size: var(--heading-3-size);
  color: var(--text-text-white);
}
.frameParent2,
.headerParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.frameParent2 {
  align-self: stretch;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--body-3-size);
}
.headerParent {
  position: absolute;
  top: calc(5% + 30px);
  left: calc(50% - 346px);
  border-radius: var(--br-mini);
  background-color: whitesmoke;
  width: 50%;
  padding: var(--padding-mini);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--heading-2-size);
  color: var(--text-text-black);
  font-family: var(--subhead-2);
}
.urunListesiN {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  position: relative;
  background-color: var(--color-gray-200);
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  text-align: left;
  font-size: var(--base-1-semibold-size);
  color: var(--color-light-body-text);
  font-family: var(--text-md-regular);



  

}
