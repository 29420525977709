.icon {
  width: 25px;
  position: relative;
  height: 35px;
  object-fit: cover;
}
.aliVeli,
.irketAd {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.irketAd {
  font-size: var(--text-xs-lineheight-4-font-bold-letterspacing-wider-size);
  line-height: 12px;
  font-weight: 500;
  color: var(--color-dark-grey-v2);
}
.name,
.user {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.user {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}
.div {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}
.number {
  width: 24px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--color-accent-01);
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.addIcon {
  width: 8px;
  position: relative;
  height: 8px;
}
.icon1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xl);
  border: 2px solid var(--neutral-03);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.iconadd {
  display: none;
}
.iconadd,
.uiIconclosefilled {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconRightcouple1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.iconRightcouple,
.userParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iconRightcouple {
  justify-content: flex-end;
  text-align: center;
  color: var(--neutral-07);
}
.userParent {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-29xl);
}
.homePageIcon {
  width: 25px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.anaSayfa {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.div1 {
  position: absolute;
  top: 4px;
  left: 6.7px;
  letter-spacing: -0.01em;
  font-weight: 600;
}
.wrapper {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-03);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
  color: var(--text-text-white);
}
.hangarIcon {
  width: 23px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.left1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.container {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-07);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
}
.frame,
.wrapper1 {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.frame {
  background-color: var(--color-accent-01);
  display: none;
}
.wrapper1 {
  background-color: var(--color-accent-04);
}
.iconmenuIcon,
.wrapper2 {
  width: 24px;
  position: relative;
  height: 24px;
}
.wrapper2 {
  width: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-04);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem5 {
  width: 290px;
  border-radius: var(--br-5xs);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-xs);
}
.logoutIcon {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: cover;
}
.navItemParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-dark-grey-v2);
}
.divider,
.navItemParent,
.top {
  align-self: stretch;
}
.top {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.divider {
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--color-dark-grey);
  height: 2px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.2;
}
.dividerParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.image6Icon,
.tr {
  position: relative;
}
.tr {
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.image6Icon {
  width: 30px;
  border-radius: var(--br-9xs);
  height: 20px;
  object-fit: cover;
}
.toggle {
  background-color: var(--text-text-white);
  box-shadow: var(--button-01);
  overflow: hidden;
  gap: var(--gap-5xs);
}
.toggle,
.toggle1 {
  flex: 1;
  border-radius: var(--br-13xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
}
.navigationDrawer,
.switch {
  align-self: stretch;
  display: flex;
}
.switch {
  border-radius: var(--br-21xl);
  background-color: var(--color-whitesmoke-200);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
}
.navigationDrawer {
  width: 300px;
  background-color: var(--text-text-white);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  box-sizing: border-box;
}
.label {
  width: 36px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}
.searchSmIcon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.helpIcon {
  width: 16px;
  position: relative;
  height: 16px;
  display: none;
}
.hintText,
.input {
  color: var(--color-slategray);
}
.input {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--color-lightgray-100);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-sm);
  gap: var(--gap-5xs);
  font-size: var(--text-md-lineheight-6-font-normal-size);
}
.hintText {
  width: 320px;
  position: relative;
  line-height: 20px;
  display: none;
}
.inputFieldBase {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-7xs);
}
.inputField,
.inputFieldBase,
.inputFieldWrapper {
  display: flex;
  justify-content: flex-start;
}
.inputField {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
}
.inputFieldWrapper {
  align-self: stretch;
  background-color: var(--color-whitesmoke-600);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-5xs) var(--padding-base);
}
.shape {
  border-radius: 6.32px;
  background-color: var(--color-mediumslateblue);
}
.btnSend,
.shape {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.componenticonicPlus {
  position: absolute;
  height: 66.56%;
  width: 66.44%;
  top: 16.56%;
  right: 16.78%;
  bottom: 16.88%;
  left: 16.78%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.add {
  width: 29.2px;
  position: relative;
  height: 32px;
}
.productsList {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--padding-3xs);
  font-size: var(--text-lg-lineheight-7-font-semibold-size);
  color: var(--color-gray-100);
}
.div9,
.oluturulanBelgeler {
  position: relative;
}
.div9 {
  font-size: var(--text-xs-lineheight-4-font-bold-letterspacing-wider-size);
  font-weight: 600;
  color: var(--grayscale-gray-light-gray);
}
.onProgressKapali,
.placeholder {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.onProgressKapali {
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  box-shadow: var(--container-light);
  border: 1px solid var(--grayscale-white-dark-white);
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-base);
  font-size: var(--text-lg-lineheight-7-font-semibold-size);
  color: var(--text-text-black);
  font-family: var(--subhead-2);
}
.dividerIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.jan092024,
.sonOluturulma {
  position: relative;
  font-weight: 600;
}
.jan092024 {
  line-height: 18px;
  font-weight: 500;
  color: var(--grayscale-gray-light-gray);
}
.badges {
  align-self: stretch;
  width: 68px;
  border-radius: var(--br-6xs) 0 0 var(--br-6xs);
  background-color: var(--color-mediumseagreen-400);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.bottom1,
.date,
.left11 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.bottom1,
.left11 {
  justify-content: center;
}
.left11 {
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
}
.div11 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
}
.container2,
.left12,
.right {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.left12 {
  align-items: flex-start;
  justify-content: center;
  color: var(--grayscale-gray-light-gray);
}
.container2,
.right {
  flex: 1;
  align-items: center;
  justify-content: flex-start;
}
.container2 {
  border-left: 1px solid var(--grayscale-white-dark-white);
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-8xs);
}
.container1,
.onProgress,
.task01 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.task01 {
  width: 598px;
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  box-shadow: var(--container-light);
  border: 1px solid var(--grayscale-white-dark-white);
  box-sizing: border-box;
  height: 55px;
  flex-direction: row;
  padding: var(--padding-12xs);
}
.container1,
.onProgress {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-xs);
  font-size: var(--text-xs-lineheight-4-font-bold-letterspacing-wider-size);
}
.onProgress {
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  box-shadow: var(--container-light);
  border: 1px solid var(--grayscale-white-dark-white);
  align-items: flex-start;
  padding: var(--padding-base) var(--padding-8xs);
  gap: var(--gap-5xl);
  font-size: var(--text-lg-lineheight-7-font-semibold-size);
  color: var(--text-text-black);
  font-family: var(--subhead-2);
}
.div13 {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 4px);
  line-height: 150%;
}
.circle {
  width: 40px;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--gray-50);
  border: 2px solid var(--green-500);
  box-sizing: border-box;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.belgeTrSein {
  position: relative;
  line-height: 150%;
}
.line {
  flex: 1;
  position: relative;
  background-color: var(--gray-50);
  height: 2px;
  overflow: hidden;
}
.step,
.stepper,
.steps {
  display: flex;
  flex-direction: row;
}
.step {
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-5xs) 0 0;
  gap: var(--gap-5xs);
}
.stepper,
.steps {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}
.steps {
  flex: 1;
}
.stepper {
  background-color: var(--gray-100);
  overflow: hidden;
  padding: var(--padding-5xl) var(--padding-8xs);
}
.xlLabel {
  position: relative;
  font-weight: 800;
}
.ctaButton,
.label1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ctaButton {
  width: 492px;
  border-radius: var(--br-3xs);
  background-color: var(--color-black);
  height: 41px;
  padding: var(--padding-xs) 0;
  box-sizing: border-box;
}
.lg,
.password,
.wrapper5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.password {
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xl) var(--padding-31xl);
  gap: var(--gap-3xs);
  font-size: var(--text-lg-lineheight-7-font-semibold-size);
  color: var(--text-text-white);
  font-family: var(--subhead-2);
}
.lg,
.wrapper5 {
  align-items: flex-start;
  justify-content: flex-start;
}
.lg {
  border-radius: var(--br-5xl);
  background-color: var(--text-text-white);
  box-shadow: var(--base);
  overflow: hidden;
}
.wrapper5 {
  padding: 0 var(--padding-8xs) var(--padding-13xl);
}
.stokKBelgesi2 {
  align-self: stretch;
  position: relative;
  line-height: 120%;
}
.content1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl);
  text-align: center;
  font-size: var(--heading-2-size);
  color: var(--color-gray-600);
}
.smallLabel {
  flex: 1;
  position: relative;
  background-color: #ffffff;
  border: none;
  outline: none;
  font-weight: 600;
}
.container6,
.placeholder2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.container6 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--grayscale-white-dark-white);
  flex-direction: column;
  padding: var(--padding-xs);
  color: var(--grayscale-white-dark-white);
}
.taskName {
  gap: var(--gap-5xs);
}
.taskName,
.taskNameWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.kYaplanStokalar {
  position: relative;
  line-height: 110%;
}
.frameWrapper {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--color-whitesmoke-300);
  box-shadow: 2px 12px 20px rgba(90, 117, 167, 0.04);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl) 0;
  font-size: var(--text-md-lineheight-6-font-normal-size);
  color: var(--color-gray-400);
  font-family: var(--font-poppins);
}
.componenticonicUncheck {
  width: 12px;
  position: relative;
  height: 12px;
}
.markaId {
  align-self: stretch;
  position: relative;
  text-align: center;
}
.backpackRidd {
  position: relative;
  font-size: var(--subhead-2-size);
  letter-spacing: 0.2px;
  font-weight: 500;
  font-family: var(--font-poppins);
  color: var(--color-gray-100);
}
.markaIdParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.componenticonicUncheckParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.separator {
  align-self: stretch;
  position: relative;
  background-color: var(--color-whitesmoke-100);
  height: 0.7px;
}
.div15 {
  position: relative;
  font-size: var(--subhead-2-size);
  letter-spacing: 0.2px;
  color: var(--color-darkslategray-100);
  text-align: left;
}
.id {
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--padding-12xs);
  gap: var(--gap-8xs);
}
.id,
.idParent,
.product4,
.var {
  display: flex;
  justify-content: center;
}
.var {
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-8xs);
}
.idParent,
.product4 {
  align-self: stretch;
}
.idParent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-8xs);
  text-align: center;
  font-size: var(--font-size-2xs);
}
.product4 {
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-mediumseagreen-100);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-8xs);
  gap: var(--gap-10xs);
}
.priorityLevelDropdown,
.product4 {
  align-items: flex-start;
}
.frameContainer,
.frameGroup,
.priorityLevelDropdown,
.product41 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product41 {
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-lightgray-200);
  overflow: hidden;
  align-items: flex-start;
  padding: var(--padding-8xs);
  gap: var(--gap-10xs);
}
.frameContainer,
.frameGroup {
  align-items: center;
  gap: var(--gap-xl);
  color: var(--color-dimgray-100);
  font-family: var(--text-md-lineheight-6-font-normal);
}
.frameGroup {
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  gap: var(--gap-6xl);
  font-size: var(--text-xs-lineheight-4-font-bold-letterspacing-wider-size);
  color: var(--text-text-black);
  font-family: var(--subhead-2);
}
.devamEt0 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.button {
  width: 384px;
  border-radius: var(--br-7xs);
  background-color: var(--primary-primary-500);
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-5xl);
  box-sizing: border-box;
}
.lg1,
.nextStepButton,
.wrapper6 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.nextStepButton {
  background-color: var(--gray-100);
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-mini);
  font-size: var(--text-lg-lineheight-7-font-semibold-size);
  color: var(--text-text-white);
}
.lg1,
.wrapper6 {
  align-items: flex-start;
  justify-content: flex-start;
}
.lg1 {
  border-radius: var(--br-5xl);
  background-color: var(--text-text-white);
  box-shadow: var(--base);
  overflow: hidden;
  gap: var(--gap-8xs);
}
.wrapper6 {
  padding: 0 0 var(--padding-13xl);
}
.image9Icon {
  width: 288px;
  position: relative;
  height: 296px;
  object-fit: cover;
}
.content2,
.header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.content2 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl) var(--padding-8xs);
}
.confirmButton,
.frameParent,
.onProgressKapaliParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.confirmButton {
  align-self: stretch;
  background-color: var(--gray-100);
  overflow: hidden;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-8xs);
  font-size: var(--text-lg-lineheight-7-font-semibold-size);
  color: var(--text-text-white);
}
.frameParent,
.onProgressKapaliParent {
  gap: var(--gap-mini);
}
.onProgressKapaliParent {
  align-self: stretch;
  border-radius: var(--br-mini);
  justify-content: center;
  padding: var(--padding-6xl) var(--padding-mini) var(--padding-mini);
  font-size: var(--text-md-lineheight-6-font-normal-size);
  color: var(--gray-900);
}
.frameParent {
  flex: 1;
  justify-content: flex-start;
  font-size: var(--subhead-2-size);
  color: var(--color-darkslategray-200);
}
.belgeCreateYeni {
  width: 100%;
  position: relative;
  background-color: var(--color-gray-200);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--text-middle-15px-bold-size);
  color: var(--color-light-body-text);
  font-family: var(--text-md-lineheight-6-font-normal);
}
