.arrowLeftIcon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.ctaButton {
  width: 40px;
  border-radius: var(--br-5xs);
  border: 2px solid var(--primary-primary-500);
  box-sizing: border-box;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) 0;
}
.rnEkle {
  position: relative;
}
.header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.rnAd {
  position: relative;
  font-weight: 600;
}
.container,
.label {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.container {
  border-radius: var(--br-5xs);
  border: 1px solid var(--grayscale-white-dark-white);
  box-sizing: border-box;
  height: 35px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xs);
  color: var(--grayscale-white-dark-white);
}
.placeholder2,
.taskName {
  display: flex;
  align-items: center;
}
.taskName {
  width: 305px;
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-5xs);
}
.placeholder2 {
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
}
.container1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  border: 1px solid var(--primary-primary-500);
  box-sizing: border-box;
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xs);
  color: var(--grayscale-white-dark-white);
}
.optionA {
  background-color: var(--grayscale-white-almost-white);
}
.optionA,
.optionA1,
.optionA4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
}
.optionA4 {
  justify-content: center;
  color: var(--color-mediumslateblue);
}
.dropdownMenu,
.priorityLevelDropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.dropdownMenu {
  align-self: stretch;
  border-radius: var(--br-3xs);
  border: 1px solid var(--grayscale-white-shaded-white);
  padding: var(--padding-12xs);
}
.priorityLevelDropdown {
  width: 281px;
  gap: var(--gap-5xs);
}
.taskNameParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.container3,
.priorityLevelDropdown1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.priorityLevelDropdown1 {
  width: 331px;
  gap: var(--gap-5xs);
}
.container3 {
  flex: 1;
  border-radius: var(--br-5xs);
  border: 1px solid var(--grayscale-white-dark-white);
  box-sizing: border-box;
  height: 35px;
  padding: var(--padding-xs);
}
.barcodeReaderIcon {
  width: 25px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.barcodeReaderParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-8xs);
}
.containerParent,
.taskName1 {
  display: flex;
  align-items: center;
  gap: var(--gap-5xs);
}
.containerParent {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  color: var(--grayscale-white-dark-white);
}
.taskName1 {
  width: 360px;
  flex-direction: column;
  justify-content: center;
}
.priorityLevelDropdownParent {
  flex-direction: column;
  gap: var(--gap-mini);
}
.frameGroup,
.priorityLevelDropdown2,
.priorityLevelDropdownParent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.frameGroup {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-31xl);
}
.priorityLevelDropdown2 {
  width: 373px;
  flex-direction: column;
  gap: var(--gap-5xs);
}
.priorityLevelDropdownGroup,
.taskName2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.taskName2 {
  width: 268px;
  flex-direction: column;
  gap: var(--gap-5xs);
}
.priorityLevelDropdownGroup {
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-6xl);
}
.aklamaEkleaklamaEkleak {
  flex: 1;
  position: relative;
  font-weight: 600;
}
.container6,
.taskName3 {
  flex-direction: column;
  justify-content: center;
}
.container6 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  border: 1px solid var(--grayscale-white-dark-white);
  display: flex;
  align-items: flex-start;
  padding: var(--padding-xs);
  color: var(--grayscale-white-dark-white);
}
.taskName3 {
  width: 466px;
  gap: var(--gap-5xs);
}
.container7,
.middle,
.taskName3 {
  display: flex;
  align-items: center;
}
.middle {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.container7 {
  width: 89.3px;
  border-radius: var(--br-5xs);
  box-shadow: var(--container-light);
  border: 1px solid var(--grayscale-white-dark-white);
  box-sizing: border-box;
  height: 71px;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-xs);
}
.image1Icon {
  width: 40px;
  position: relative;
  height: 43px;
  object-fit: cover;
}
.containerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
  color: var(--grayscale-gray-light-gray);
}
.xlLabel {
  position: relative;
  font-weight: 800;
}
.label7 {
  flex-direction: row;
  justify-content: center;
}
.ctaButton1,
.frameParent,
.label7 {
  display: flex;
  align-items: center;
}
.ctaButton1 {
  width: 397px;
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
  height: 55px;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-xs) 0;
  box-sizing: border-box;
  font-size: var(--heading-3-size);
  color: var(--text-text-white);
}
.frameParent {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--body-3-size);
}
.headerParent {
  width: 792px;
  border-radius: var(--br-mini);
  background-color: var(--text-text-white);
  justify-content: flex-start;
  padding: var(--padding-mini);
  box-sizing: border-box;
  gap: var(--gap-3xs);
}
.headerParent,
.urunEkle,
.urunEkleInner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.urunEkleInner {
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-mini);
  justify-content: center;
  padding: var(--padding-mini);
}
.urunEkle {
  width: 100%;
  position: relative;
  height: 982px;
  overflow: hidden;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--heading-2-size);
  color: var(--text-text-black);
  font-family: var(--subhead-2);
}
