.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Overlay için arka plan rengi ve opaklık */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    padding: 20px;
    border-radius: 5px;
    z-index: 99999;

  }
  
  .popup-inner {
    text-align: center;
    z-index: 99999;

  }
  
  .popup button {
    margin-top: 10px;
    z-index: 99999;

  }
  
  