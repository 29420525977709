.icon {
  width: 25px;
  position: relative;
  height: 35px;
  object-fit: cover;
}
.aliVeli,
.irketAd {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.irketAd {
  font-size: var(--body-3-size);
  line-height: 12px;
  font-weight: 500;
  color: var(--color-dark-grey-v2);
}
.name,
.user {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.user {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}
.div {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}
.number {
  width: 24px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--color-accent-01);
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.addIcon {
  width: 8px;
  position: relative;
  height: 8px;
}
.icon1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xl);
  border: 2px solid var(--neutral-03);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.iconadd {
  display: none;
}
.iconadd,
.uiIconclosefilled {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconRightcouple1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.iconRightcouple,
.userParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iconRightcouple {
  justify-content: flex-end;
  text-align: center;
  color: var(--neutral-07);
}
.userParent {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-29xl);
}
.homePageIcon {
  width: 25px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.anaSayfa {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.div1 {
  position: absolute;
  top: 4px;
  left: 6.7px;
  letter-spacing: -0.01em;
  font-weight: 600;
}
.wrapper {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-03);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
  color: var(--text-text-white);
}
.hangarIcon {
  width: 23px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.left1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.container {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-07);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
}
.frame,
.wrapper1 {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.frame {
  background-color: var(--color-accent-01);
  display: none;
}
.wrapper1 {
  background-color: var(--color-accent-04);
}
.iconmenuIcon,
.wrapper2 {
  width: 24px;
  position: relative;
  height: 24px;
}
.wrapper2 {
  width: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-04);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem5 {
  width: 290px;
  border-radius: var(--br-5xs);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-xs);
}
.logoutIcon {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: cover;
}
.navItemParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-dark-grey-v2);
}
.divider,
.navItemParent,
.top {
  align-self: stretch;
}
.top {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.divider {
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--color-dark-grey);
  height: 2px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.2;
}
.dividerParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.image6Icon,
.tr {
  position: relative;
}
.tr {
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.image6Icon {
  width: 30px;
  border-radius: var(--br-9xs);
  height: 20px;
  object-fit: cover;
}
.toggle {
  flex: 1;
  border-radius: var(--br-13xl);
  background-color: var(--text-text-white);
  box-shadow: var(--button-01);
  overflow: hidden;
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
}
.switch,
.toggle,
.toggle1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.toggle1 {
  flex: 1;
  border-radius: var(--br-13xl);
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
}
.switch {
  align-self: stretch;
  border-radius: var(--br-21xl);
  background-color: var(--color-whitesmoke-200);
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
}
.navigationDrawer {
  align-self: stretch;
  width: 300px;
  background-color: var(--text-text-white);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  box-sizing: border-box;
}
.ctaButton {
  width: 40px;
  border-radius: var(--br-5xs);
  border: 2px solid var(--primary-primary-500);
  box-sizing: border-box;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) 0;
}
.yedekAl {
  flex: 1;
  position: relative;
}
.header {
  align-self: stretch;
  background-color: var(--text-text-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-8xs) var(--padding-base);
  gap: var(--gap-base);
}
.xlLabel {
  position: relative;
  font-weight: 800;
}
.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ctaButton1 {
  width: 250px;
  border-radius: var(--br-5xs);
  background-color: var(--color-mediumseagreen-300);
  height: 39px;
  padding: var(--padding-xs) 0;
  box-sizing: border-box;
}
.ctaButton1,
.ctaButton2,
.ctaButtonParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ctaButton2 {
  width: 250px;
  border-radius: var(--br-5xs);
  background-color: var(--color-sandybrown);
  height: 39px;
  padding: var(--padding-xs) 0;
  box-sizing: border-box;
}
.ctaButtonParent {
  align-self: stretch;
  padding: var(--padding-mini) var(--padding-131xl);
  gap: var(--gap-6xl);
  font-size: var(--font-size-5xl);
  color: var(--text-text-white);
}
.div9 {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 4px);
  line-height: 150%;
}
.circle {
  width: 40px;
  border-radius: var(--br-xl);
  background-color: var(--gray-50);
  border: 2px solid var(--green-500);
  box-sizing: border-box;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.circle,
.line,
.step1 {
  position: relative;
}
.step1 {
  line-height: 150%;
}
.line {
  flex: 1;
  background-color: var(--gray-50);
  height: 2px;
  overflow: hidden;
}
.step,
.textAndLine {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.step {
  justify-content: center;
  padding: 0 var(--padding-5xs) 0 0;
}
.circle1,
.circle3,
.line3 {
  position: relative;
  overflow: hidden;
}
.circle1 {
  width: 40px;
  border-radius: var(--br-xl);
  background-color: var(--gray-50);
  height: 40px;
  flex-shrink: 0;
}
.circle3,
.line3 {
  background-color: var(--gray-200);
}
.circle3 {
  width: 40px;
  border-radius: var(--br-xl);
  height: 40px;
  flex-shrink: 0;
}
.line3 {
  flex: 1;
  height: 2px;
}
.step4,
.step5 {
  display: none;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-5xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.step4 {
  width: 69.9px;
  align-items: center;
}
.step5 {
  width: 79.9px;
}
.step5,
.step6,
.step7 {
  align-items: center;
}
.step6 {
  width: 93.2px;
  display: none;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-5xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.step7 {
  width: 111.8px;
}
.step7,
.step8,
.step9 {
  display: none;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-5xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.step8 {
  width: 139.8px;
  align-items: center;
}
.step9 {
  width: 186.3px;
}
.step9,
.textAndLine9 {
  align-items: center;
}
.step10,
.stepper,
.steps,
.textAndLine9 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.step10 {
  align-items: center;
  gap: var(--gap-5xs);
}
.stepper,
.steps {
  align-self: stretch;
  align-items: flex-start;
}
.steps {
  flex: 1;
}
.stepper {
  background-color: var(--gray-100);
  overflow: hidden;
  padding: var(--padding-5xl) var(--padding-13xl);
}
.dosyaYkle {
  position: relative;
  line-height: 120%;
}
.ykleyebileceinizTrler {
  margin: 0;
  line-height: 32px;
  font-weight: 600;
}
.xlsxcsvxls {
  margin: 0;
  font-size: var(--text-md-regular-size);
  line-height: 24px;
  color: #718096;
}
.ykleyebileceinizTrlerXContainer {
  position: relative;
  font-size: var(--font-size-5xl);
}
.urun {
  position: absolute;
  width: calc(100% - 47.7px);
  top: calc(50% - 8px);
  left: 24px;
  letter-spacing: 0.05em;
  line-height: 16px;
  display: flex;
  align-items: center;
}
.head {
  align-self: stretch;
  position: relative;
  background-color: var(--text-text-white);
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--body-3-size);
}
.urunAdi {
  position: absolute;
  width: calc(100% - 47.7px);
  top: calc(50% - 10px);
  left: 24px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.row {
  align-self: stretch;
  position: relative;
  background-color: var(--text-text-white);
  height: 52px;
  overflow: hidden;
  flex-shrink: 0;
}
.markChandler {
  position: absolute;
  width: calc(100% - 48px);
  top: calc(50% - 10px);
  left: 24px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.row1 {
  width: 260px;
  position: relative;
  background-color: var(--text-text-white);
  height: 52px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.adet1 {
  width: calc(100% - 47.7px);
  top: calc(50% - 10px);
  line-height: 20px;
  display: flex;
  align-items: center;
}
.adet1,
.developer,
.kaynak {
  position: absolute;
  left: 24px;
}
.developer {
  width: calc(100% - 48px);
  top: calc(50% - 10px);
  line-height: 20px;
  display: flex;
  align-items: center;
}
.kaynak {
  top: calc(50% - 14px);
  letter-spacing: 0.05em;
  line-height: 16px;
}
.head2 {
  align-self: stretch;
  position: relative;
  background-color: var(--text-text-white);
  height: 52px;
  font-size: var(--body-3-size);
}
.column2 {
  flex: 1;
  height: 92px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.fadeOut,
.table {
  position: absolute;
  width: 1385px;
}
.table {
  top: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--text-text-white);
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 105px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs);
}
.fadeOut {
  bottom: 0;
  left: calc(50% - 693px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff);
  height: 59px;
  overflow: hidden;
}
.tableExample {
  align-self: stretch;
  position: relative;
  height: 105px;
  font-size: var(--subhead-2-size);
}
.uploadXlsxXls {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.button,
.textAndButton {
  display: flex;
  align-items: center;
}
.button {
  border-radius: var(--br-7xs);
  background-color: var(--primary-500);
  height: 48px;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-5xl);
  box-sizing: border-box;
  color: var(--text-text-white);
}
.textAndButton {
  position: absolute;
  top: calc(50% - 45.5px);
  left: calc(50% - 131px);
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.uploadDropzone {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs);
  border: 2px dashed var(--primary-500);
  overflow: hidden;
  font-size: var(--heading-3-size);
}
.content {
  flex: 1;
  padding: var(--padding-13xl);
  gap: var(--gap-13xl);
  font-size: var(--component-heading-large-lg-size);
  color: var(--gray-700);
}
.content,
.lg,
.wrapper5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.lg {
  flex: 1;
  border-radius: var(--br-5xl);
  background-color: var(--text-text-white);
  box-shadow: var(--base);
  overflow: hidden;
}
.wrapper5 {
  height: 707px;
  padding: 0 var(--padding-13xl) var(--padding-13xl);
  box-sizing: border-box;
}
.yklemekStediinizSayfa {
  position: relative;
  font-size: var(--component-heading-large-lg-size);
  line-height: 120%;
  color: var(--gray-700);
  text-align: center;
}
.radio {
  width: 20px;
  position: relative;
  border-radius: var(--br-9980xl);
  background-color: var(--text-text-white);
  border: 6px solid var(--blue-500);
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.selection {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.radio1 {
  width: 20px;
  position: relative;
  border-radius: var(--br-9980xl);
  background-color: var(--text-text-white);
  border: 2px solid var(--gray-200);
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.center {
  align-self: stretch;
  flex-direction: column;
  padding: 0 112px;
  gap: var(--gap-13xl);
}
.button2,
.center,
.content1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1 {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  padding: var(--padding-13xl);
  color: var(--color-black);
}
.button2 {
  width: 335px;
  cursor: pointer;
  border-radius: var(--br-7xs);
  background-color: var(--primary-500);
  height: 48px;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl);
  box-sizing: border-box;
}
.content2,
.nextStepButton,
.wrapper6 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.nextStepButton {
  background-color: var(--gray-100);
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-13xl);
  font-size: var(--heading-3-size);
  color: var(--text-text-white);
}
.content2,
.wrapper6 {
  align-items: flex-start;
  justify-content: flex-start;
}
.wrapper6 {
  height: 950px;
  padding: 0 var(--padding-13xl) var(--padding-13xl);
  box-sizing: border-box;
}
.content2 {
  padding: var(--padding-13xl);
  font-size: var(--component-heading-large-lg-size);
  color: #1f2933;
}
.urunAd {
  position: relative;
  line-height: 110%;
}
.frameContainer,
.urunAdWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameContainer {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
}
.frameChild {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.label2 {
  align-self: stretch;
  height: 15px;
}
.smallLabel {
  position: relative;
  font-weight: 600;
}
.container1,
.placeholder {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
}
.container1 {
  border-radius: var(--br-5xs);
  border: 1px solid var(--primary-primary-500);
  box-sizing: border-box;
  height: 35px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xs);
}
.optionA {
  background-color: var(--grayscale-white-almost-white);
}
.optionA,
.optionA1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
}
.dropdownMenu {
  align-self: stretch;
  border-radius: var(--br-3xs);
  border: 1px solid var(--grayscale-white-shaded-white);
  align-items: flex-start;
  padding: var(--padding-12xs);
  color: var(--text-text-black);
}
.dropdownMenu,
.frameGroup,
.frameWrapper,
.priorityLevelDropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.priorityLevelDropdown {
  width: 331px;
  align-items: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--body-3-size);
  color: var(--color-gray-500);
  font-family: var(--subhead-2);
}
.frameGroup,
.frameWrapper {
  align-items: center;
}
.frameGroup {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--color-whitesmoke-300);
  box-shadow: 2px 12px 20px rgba(90, 117, 167, 0.04);
  padding: var(--padding-xl) var(--padding-base);
}
.frameWrapper {
  width: 375px;
}
.frameParent,
.lg2,
.wrapper7 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.frameParent {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 var(--padding-3xs);
  gap: var(--gap-6xl);
  color: var(--color-gray-400);
  font-family: var(--font-poppins);
}
.lg2,
.wrapper7 {
  flex-direction: column;
  justify-content: flex-start;
}
.lg2 {
  border-radius: var(--br-5xl);
  background-color: var(--text-text-white);
  box-shadow: var(--base);
  height: 918px;
  overflow: hidden;
  flex-shrink: 0;
  gap: var(--gap-8xs);
}
.wrapper7 {
  padding: 0 var(--padding-13xl) var(--padding-13xl);
}
.button7 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button6 {
  border-radius: var(--br-7xs);
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-base);
}
.switch1,
.thumb {
  border-radius: var(--br-9980xl);
  overflow: hidden;
}
.thumb {
  position: absolute;
  height: 85.71%;
  width: 48%;
  top: 7.14%;
  right: 48%;
  bottom: 7.14%;
  left: 4%;
  background-color: var(--text-text-white);
}
.switch1 {
  width: 50px;
  position: relative;
  background-color: var(--gray-300);
  height: 28px;
  flex-shrink: 0;
}
.showOnlyRows {
  position: relative;
  line-height: 24px;
}
.switchFilterErrors {
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-black);
}
.discardAndSwitch,
.header1,
.switchFilterErrors {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.discardAndSwitch {
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--text-md-regular-size);
}
.header1 {
  align-self: stretch;
  justify-content: space-between;
}
.border,
.rn {
  position: relative;
}
.rn {
  letter-spacing: 0.05em;
  line-height: 16px;
}
.border {
  align-self: stretch;
  background-color: var(--gray-200);
  height: 1px;
}
.rnParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
  font-size: var(--body-3-size);
  color: var(--gray-600);
}
.founderOfChakra {
  position: relative;
  line-height: 20px;
}
.founderOfChakraUiParent {
  display: flex;
  flex-direction: column;
  gap: var(--gap-2xs);
}
.content3,
.founderOfChakraUiParent,
.table1 {
  align-items: flex-start;
  justify-content: flex-start;
}
.table1 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--text-text-white);
  border: 1px solid var(--gray-200);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  gap: var(--gap-6xl);
  font-size: var(--subhead-2-size);
}
.content3 {
  padding: var(--padding-13xl);
  gap: var(--gap-13xl);
  font-size: var(--component-heading-large-lg-size);
  color: var(--gray-700);
}
.content3,
.lg3,
.wrapperParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.lg3 {
  border-radius: var(--br-5xl);
  background-color: var(--text-text-white);
  box-shadow: var(--base);
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
}
.wrapperParent {
  border-radius: var(--br-mini);
  align-items: center;
  justify-content: center;
  padding: var(--padding-81xl) var(--padding-mini) var(--padding-mini);
  gap: var(--gap-mini);
  font-size: var(--text-md-regular-size);
  color: var(--gray-900);
  font-family: var(--text-md-regular);
}
.exceldengecis,
.headerParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.headerParent {
  flex: 1;
  flex-direction: column;
  padding: 0 var(--padding-3xs) var(--padding-731xl);
  gap: var(--gap-6xs);
  font-size: var(--heading-2-size);
  color: var(--text-text-black);
  font-family: var(--subhead-2);
}
.exceldengecis {
  width: 100%;
  position: relative;
  background-color: var(--color-gray-200);
  overflow: hidden;
  flex-direction: row;
  text-align: left;
  font-size: var(--base-1-semibold-size);
  color: var(--color-light-body-text);
  font-family: var(--text-md-regular);
}
