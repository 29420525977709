@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --text-md-regular: Inter;
  --font-poppins: Poppins;
  --font-plus-jakarta-sans: "Plus Jakarta Sans";

  /* font sizes */
  --caption-2-medium-size: 12px;
  --font-size-sm: 14px;
  --text-md-regular-size: 16px;
  --font-size-4xs: 9px;
  --font-size-mid-9: 17.9px;
  --font-size-3xl: 22px;
  --font-size-2xs: 11px;
  --font-size-lg: 18px;
  --base-1-semibold-size: 15px;
  --font-size-3xs: 10px;
  --font-size-5xl: 24px;

  /* Colors */
  --color-gray-200: #fafafb;
  --color-light-body-text: #1a1b2d;
  --neutral-07: #1a1d1f;
  --color-gray-100: #171725;
  --color-light-white: #fff;
  --color-mediumslateblue: #FFCD38;
  --color-lightslategray: #92929d;
  --color-darkslategray-100: #44444f;
  --color-darkslategray-200: #344154;
  --text-text-black: #333;
  --color-mediumseagreen-100: #3dd598;
  --color-mediumseagreen-200: #3cd598;
  --color-whitesmoke-100: #f1f1f5;
  --color-whitesmoke-200: #f0f0f0;
  --neutral-03: #efefef;
  --grayscale-white-almost-white: #eee;
  --color-goldenrod: #ffc542;
  --color-sandybrown: #ff974a;
  --color-dimgray-100: #696974;
  --color-dark-grey-v2: #535763;
  --color-crimson: #f30066;
  --color-slategray: #667085;
  --color-lightgray-100: #d0d5dd;
  --color-dark-grey: #d1d3d4;
  --grayscale-white-dark-white: #ccc;
  --color-accent-04: #b5ebcd;
  --color-black: #000;
  --color-accent-01: #ffbc99;
  --color-accent-07: #fb9b9b;
  --primary-primary-500: #FFCD38;
  --color-accent-03: #b1e6fc;
  --color-gainsboro-100: #e2e2ea;
  --grayscale-white-shaded-white: #ddd;
  --color-limegreen: #00ba1e;
  --color-red: #ff0101;

  /* Gaps */
  --gap-base: 16px;
  --gap-xl: 20px;
  --gap-3xs: 10px;
  --gap-7xs: 6px;
  --gap-48xl: 67px;
  --gap-6xl: 25px;
  --gap-8xs: 5px;
  --gap-smi: 13px;
  --gap-4xs: 9px;
  --gap-mini: 15px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-13xl: 32px;
  --gap-29xl: 48px;
  --gap-12xs: 1px;
  --gap-sm: 14px;
  --gap-11xs: 2px;

  /* Paddings */
  --padding-6xl: 25px;
  --padding-mini: 15px;
  --padding-3xs: 10px;
  --padding-8xs: 5px;
  --padding-xs: 12px;
  --padding-base: 16px;
  --padding-5xs: 8px;
  --padding-sm: 14px;
  --padding-9xs: 4px;
  --padding-731xl: 750px;
  --padding-81xl: 100px;
  --padding-12xs: 1px;
  --padding-11xs: 2px;

  /* Border radiuses */
  --br-xl: 20px;
  --br-7xs: 6px;
  --br-6xs-3: 6.3px;
  --br-5xs: 8px;
  --br-21xl: 40px;
  --br-13xl: 32px;
  --br-9xs: 4px;
  --br-11xs: 2px;
  --br-5xl: 24px;
  --br-mini: 15px;
  --br-3xs: 10px;

  /* Effects */
  --button-01: 0px -1px 1px rgba(0, 0, 0, 0.04) inset,
    0px 2px 0px rgba(255, 255, 255, 0.25) inset,
    0px 4px 8px -4px rgba(0, 0, 0, 0.25);
}
