.frameChild {
    width: 8px;
    position: relative;
    height: 87px;
  }
  .shortInner {
    position: absolute;
    top: 449px;
    left: 79px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameItem {
    width: 8px;
    position: relative;
    height: 240px;
  }
  .shortChild {
    position: absolute;
    top: 162px;
    left: 79px;
    height: 240px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .shapeIcon,
  .t11Icon {
    width: 67.1px;
    position: relative;
    height: 48px;
    object-fit: cover;
  }
  .shapeIcon {
    width: 21px;
    height: 12px;
    cursor: pointer;
    object-fit: contain;
  }
  .sayfalar {
    position: absolute;
    top: 8px;
    left: 0;
    line-height: 18px;
  }
  .frameInner,
  .sayfalarWrapper {
    align-self: stretch;
    position: relative;
    height: 32px;
  }
  .frameInner {
    border-top: 1px solid var(--color-gray-600);
    box-sizing: border-box;
    height: 1px;
  }
  .hangarIcon,
  .homePageIcon {
    width: 30px;
    position: relative;
    height: 30px;
    object-fit: cover;
  }
  .hangarIcon {
    width: 25px;
    cursor: pointer;
    height: 25px;
  }
  .homePageParent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .aralar {
    position: relative;
    line-height: 18px;
  }
  .createDocumentParent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    
  }
  .frameGroup {
    justify-content: flex-start;
    gap: var(--gap-mini);
  }
  .frameContainer,
  .frameGroup,
  .frameParent,
  .logoutWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .logoutWrapper {
    height: 56px;
    justify-content: center;
    
  }
  .frameContainer,
  .frameParent {
    justify-content: flex-start;
  }
  .frameContainer {
    width: 67.1px;
    gap: var(--gap-mini);
    cursor: pointer;
  }
  .frameParent {
    position: absolute;
    top: 14px;
    left: calc(50% - 34px);
    gap: var(--gap-6xl);
  }
  .short {
    width: 92px;
    position: relative;
    border-radius: var(--br-xs) 0 var(--br-xs) var(--br-xs);
    background-color: #F5F5F5;
    height: 1117px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .ymenukapali {
    background-color: var(--color-gray-200);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    font-size: var(--subhead-2-size);
    color: var(--text-secondary);
    font-family: var(--text-md-regular);
  }
  