.icon {
  width: 25px;
  position: relative;
  height: 35px;
  object-fit: cover;
}
.aliVeli,
.irketAd {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.irketAd {
  font-size: var(--text-xs-lineheight-4-font-bold-letterspacing-wider-size);
  line-height: 12px;
  font-weight: 500;
  color: var(--color-dark-grey-v2);
}
.name,
.user {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.user {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}
.div {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}
.number {
  width: 24px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--color-accent-01);
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.addIcon {
  width: 8px;
  position: relative;
  height: 8px;
}
.icon1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xl);
  border: 2px solid var(--neutral-03);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.iconadd {
  display: none;
}
.iconadd,
.uiIconclosefilled {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconRightcouple1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.iconRightcouple,
.userParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iconRightcouple {
  justify-content: flex-end;
  text-align: center;
  color: var(--neutral-07);
}
.userParent {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-29xl);
}
.homePageIcon {
  width: 25px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.anaSayfa {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.div1 {
  position: absolute;
  top: 4px;
  left: 6.7px;
  letter-spacing: -0.01em;
  font-weight: 600;
}
.wrapper {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-03);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
  color: var(--text-text-white);
}
.hangarIcon {
  width: 23px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.left1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.container {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-07);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
}
.frame,
.wrapper1 {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.frame {
  background-color: var(--color-accent-01);
  display: none;
}
.wrapper1 {
  background-color: var(--color-accent-04);
}
.iconmenuIcon,
.wrapper2 {
  width: 24px;
  position: relative;
  height: 24px;
}
.wrapper2 {
  width: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-04);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem5 {
  width: 290px;
  border-radius: var(--br-5xs);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-xs);
}
.logoutIcon {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: cover;
}
.navItemParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-dark-grey-v2);
}
.divider,
.navItemParent,
.top {
  align-self: stretch;
}
.top {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.divider {
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--color-dark-grey);
  height: 2px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.2;
}
.dividerParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.image6Icon,
.tr {
  position: relative;
}
.tr {
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.image6Icon {
  width: 30px;
  border-radius: var(--br-9xs);
  height: 20px;
  object-fit: cover;
}
.toggle {
  background-color: var(--text-text-white);
  box-shadow: var(--button-01);
  overflow: hidden;
  gap: var(--gap-5xs);
}
.toggle,
.toggle1 {
  flex: 1;
  border-radius: var(--br-13xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
}
.navigationDrawer,
.switch {
  align-self: stretch;
  display: flex;
}
.switch {
  border-radius: var(--br-21xl);
  background-color: var(--color-whitesmoke-200);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
}
.navigationDrawer {
  width: 300px;
  background-color: var(--text-text-white);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  box-sizing: border-box;
  color: var(--color-light-body-text);
  font-family: var(--text-md-regular);
}
.ctaButton,
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ctaButton {
  width: 40px;
  border-radius: var(--br-5xs);
  border: 2px solid var(--primary-primary-500);
  box-sizing: border-box;
  height: 40px;
  justify-content: center;
  padding: var(--padding-xs) 0;
}
.header {
  align-self: stretch;
  background-color: var(--text-text-white);
  justify-content: flex-start;
  padding: var(--padding-8xs) var(--padding-base);
  gap: var(--gap-base);
  font-size: var(--heading-2-size);
}
.stokSmi {
  flex: 1;
  position: relative;
  font-weight: 800;
}
.header1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-base);
  text-align: center;
  font-size: 32px;
}
.xlLabel {
  position: relative;
  font-weight: 800;
}
.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ctaButton1 {
  width: 250px;
  border-radius: var(--br-5xs);
  background-color: var(--color-mediumseagreen-300);
  height: 39px;
  padding: var(--padding-xs) 0;
  box-sizing: border-box;
}
.ctaButton1,
.ctaButton2,
.ctaButtonParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ctaButton2 {
  width: 250px;
  border-radius: var(--br-5xs);
  background-color: var(--color-salmon);
  height: 39px;
  padding: var(--padding-xs) 0;
  box-sizing: border-box;
}
.ctaButtonParent {
  align-self: stretch;
  padding: var(--padding-mini) var(--padding-131xl);
  gap: var(--gap-6xl);
  color: var(--text-text-white);
}
.markaId {
  flex: 1;
  position: relative;
}
.ctaButton3,
.markaIdParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ctaButton3 {
  width: 87px;
  border-radius: var(--br-10xs);
  background-color: var(--color-salmon);
  height: 22px;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--text-text-white);
  font-family: var(--subhead-2);
}
.markaIdParent {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.product4,
.stokKaytTarihi {
  align-self: stretch;
  display: flex;
  justify-content: center;
}
.product4 {
  border-radius: var(--br-8xs);
  background-color: var(--color-whitesmoke-300);
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-8xs);
}
.stokKaytTarihi {
  position: relative;
  font-size: var(--font-size-5xs);
  line-height: 18px;
  font-weight: 500;
  font-family: var(--subhead-2);
  color: var(--grayscale-gray-light-gray);
  text-align: center;
  align-items: center;
  height: 9px;
  flex-shrink: 0;
}
.frameContainer,
.frameWrapper,
.product4Parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.product4Parent {
  border-radius: var(--br-mini);
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0;
  gap: var(--gap-11xs);
}
.frameContainer,
.frameWrapper {
  align-items: center;
}
.frameContainer {
  height: 53px;
  justify-content: flex-start;
}
.frameWrapper {
  justify-content: center;
  padding: 0 var(--padding-231xl);
  color: var(--color-dimgray-100);
  font-family: var(--text-md-regular);
}
.largeLabel {
  position: relative;
  font-weight: 600;
}
.tab01,
.tab02 {
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xs);
}
.tab01 {
  width: 130.7px;
  display: none;
  align-items: center;
  box-sizing: border-box;
}
.tab02 {
  flex: 1;
  border-radius: var(--br-6xl);
  background-color: var(--others-subtle-blue);
  color: var(--primary-primary-600);
}
.tab02,
.tabs,
.tabs1 {
  display: flex;
  align-items: center;
}
.tabs1 {
  width: 648px;
  flex-direction: row;
  justify-content: flex-start;
}
.tabs {
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  padding: 0 var(--padding-base);
  font-size: var(--text-md-regular-size);
  color: var(--grayscale-gray-light-gray);
}
.labelChild,
.pieChartIcon {
  width: 200px;
  position: relative;
  height: 200px;
}
.labelChild {
  width: 10px;
  border-radius: 50%;
  background-color: #c9f4fb;
  border: 1px solid var(--grayscale-white-dark-white);
  box-sizing: border-box;
  height: 10px;
}
.label6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.labelItem {
  width: 10px;
  position: relative;
  border-radius: 50%;
  background-color: #fffacc;
  border: 1px solid var(--grayscale-white-dark-white);
  box-sizing: border-box;
  height: 10px;
}
.chart,
.horizontal {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.chart {
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xl) 0;
  gap: var(--gap-base);
  font-size: var(--font-size-5xs);
}
.sonStokGiri {
  position: relative;
  line-height: 18px;
  font-weight: 500;
}
.mart {
  align-self: stretch;
  position: relative;
  font-size: var(--text-lg-lineheight-7-font-semibold-size);
  color: var(--text-text-black);
  text-align: center;
}
.totalGoals {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  box-shadow: var(--container-light);
  height: 91px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--font-size-3xs);
}
.b {
  align-self: stretch;
  position: relative;
  font-size: var(--text-lg-lineheight-7-font-semibold-size);
  color: var(--text-text-black);
}
.completedGoals {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  box-shadow: var(--container-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs);
  gap: var(--gap-5xs);
}
.stokstokKategorisi {
  width: 67px;
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
}
.successRate {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  box-shadow: var(--container-light);
  height: 91px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.horizontal1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.kaynakDalm {
  position: relative;
  font-size: var(--text-xs-lineheight-4-font-bold-letterspacing-wider-size);
  line-height: 18px;
  font-weight: 500;
  color: var(--color-black);
  text-align: left;
}
.aKayna {
  align-self: stretch;
  position: relative;
}
.completionTime,
.stats {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.completionTime {
  border-radius: var(--br-5xs);
  background-color: var(--text-text-white);
  box-shadow: var(--container-light);
  align-items: center;
  padding: var(--padding-xs);
  gap: var(--gap-5xs);
  font-size: var(--text-lg-lineheight-7-font-semibold-size);
  color: var(--text-text-black);
}
.stats {
  align-items: flex-start;
  padding: 0 var(--padding-base);
  gap: var(--gap-xl);
  text-align: center;
  color: var(--color-black);
}
.profit {
  position: relative;
  letter-spacing: 0.1px;
  font-weight: 600;
}
.selectorIcon {
  width: 39px;
  position: relative;
  height: 3px;
  object-fit: contain;
}
.profitParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.candidates {
  position: relative;
  letter-spacing: 0.1px;
  font-weight: 500;
  color: var(--color-gray-100);
}
.frameParent {
  position: absolute;
  top: 18px;
  left: calc(50% - 116px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-23xl);
}
.separatorIcon {
  position: absolute;
  height: 1.79%;
  width: 100%;
  top: 98.21%;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.tabMenu {
  align-self: stretch;
  position: relative;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--subhead-2-size);
  color: var(--color-mediumslateblue);
  font-family: var(--text-md-regular);
}
.recommended1 {
  width: 61.6px;
  position: relative;
  letter-spacing: 0.07px;
  font-weight: 600;
  display: inline-block;
}
.objectIcon,
.shape {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.shape {
  border-radius: var(--br-8xs);
  background-color: var(--text-text-white);
  border: 1px solid var(--color-whitesmoke-100);
  box-sizing: border-box;
}
.objectIcon {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.div9 {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 9px);
  letter-spacing: 0.1px;
  font-weight: 600;
}
.image {
  width: 32px;
  position: relative;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}
.industries {
  position: relative;
  font-size: var(--text-xs-lineheight-4-font-bold-letterspacing-wider-size);
  letter-spacing: 0.09px;
  font-weight: 500;
  color: var(--color-darkslategray-100);
}
.imageParent,
.newWorldFundParent {
  display: flex;
  justify-content: flex-start;
}
.newWorldFundParent {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-11xs);
  color: var(--color-gray-100);
}
.imageParent {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-3xs);
}
.shape1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-sandybrown);
  box-sizing: border-box;
  opacity: 0.25;
  mix-blend-mode: normal;
}
.midRisk1 {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 17px);
  letter-spacing: 0.09px;
  font-weight: 500;
}
.midRisk {
  width: 68px;
  position: relative;
  height: 30px;
  overflow: hidden;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--text-xs-lineheight-4-font-bold-letterspacing-wider-size);
  color: var(--color-sandybrown);
}
.div10,
.year1 {
  position: relative;
  letter-spacing: 0.09px;
}
.div10 {
  font-size: var(--font-size-3xs);
  letter-spacing: 0.1px;
  font-weight: 600;
}
.year,
.yearParent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.year {
  overflow: hidden;
  flex-direction: column;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-12xs);
}
.yearParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-mini);
}
.div14 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-3xs);
  letter-spacing: 0.1px;
  font-weight: 600;
}
.year8 {
  overflow: hidden;
  justify-content: center;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-12xs);
}
.frameGroup,
.frameParent2,
.funds1,
.year8 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frameParent2 {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-sm);
  font-size: var(--text-xs-lineheight-4-font-bold-letterspacing-wider-size);
  color: var(--color-dimgray-100);
}
.frameGroup,
.funds1 {
  justify-content: center;
}
.frameGroup {
  height: 153px;
  gap: var(--gap-6xl);
}
.funds1 {
  flex: 1;
  overflow: hidden;
  padding: var(--padding-6xl) var(--padding-3xs);
  font-size: var(--subhead-2-size);
  color: var(--color-gray-200);
}
.recommended {
  border-radius: var(--br-mini);
  background-color: var(--text-text-white);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 233px;
  padding: var(--padding-8xs) var(--padding-mini);
}
.funds12,
.recommended,
.recommended4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.funds12 {
  height: 203px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-6xl) var(--padding-3xs);
  box-sizing: border-box;
  font-size: var(--subhead-2-size);
  color: var(--color-gray-200);
}
.recommended4 {
  border-radius: var(--br-mini);
  background-color: var(--text-text-white);
  border: 1px solid var(--color-gainsboro-100);
  padding: var(--padding-8xs) var(--padding-mini);
}
.headerParent,
.recommendedParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.recommendedParent {
  align-self: stretch;
  padding: 0 var(--padding-131xl);
  font-size: var(--text-middle-15px-bold-size);
  color: var(--color-dimgray-100);
  font-family: var(--text-md-regular);
}
.headerParent {
  flex: 1;
  font-size: var(--text-xs-lineheight-4-font-bold-letterspacing-wider-size);
}
.productsList {
  font-size: var(--font-size-5xl);
  font-weight: 600;
  font-family: var(--text-md-regular);
  color: var(--color-gray-100);
}
.adet,
.palet1,
.productsList {
  position: relative;
}
.adet {
  font-size: var(--text-xs-lineheight-4-font-bold-letterspacing-wider-size);
  font-weight: 600;
  color: var(--grayscale-gray-light-gray);
}
.left9,
.onProgressKapali,
.placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left9 {
  flex: 1;
  gap: var(--gap-5xs);
}
.onProgressKapali,
.placeholder {
  align-self: stretch;
}
.onProgressKapali {
  border-radius: var(--br-5xs);
  box-shadow: var(--container-light);
  border: 1px solid var(--grayscale-white-dark-white);
  box-sizing: border-box;
  height: 25px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-base);
}
.dividerIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.container1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
  font-size: var(--text-xs-lineheight-4-font-bold-letterspacing-wider-size);
  color: var(--color-dimgray-100);
  font-family: var(--text-md-regular);
}
.onProgress,
.onProgressKapaliParent,
.productsListParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.onProgress {
  border-radius: var(--br-5xs);
  box-shadow: var(--container-light);
  border: 1px solid var(--grayscale-white-dark-white);
  align-items: flex-start;
  padding: var(--padding-base);
  gap: var(--gap-5xl);
  font-size: var(--font-size-smi);
}
.onProgressKapaliParent,
.productsListParent {
  align-items: center;
  gap: var(--gap-8xs);
}
.productsListParent {
  border-radius: var(--br-3xs);
  padding: var(--padding-6xl) var(--padding-8xs);
  gap: var(--gap-3xs);
}
.barkodList,
.stokBilgi {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}
.barkodList {
  align-self: stretch;
  width: 320px;
  top: -50px;
  position: relative;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-81xl) 0;
  box-sizing: border-box;
  font-size: var(--font-size-3xs);
}
.stokBilgi {
  width: 100%;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  font-size: var(--text-middle-15px-bold-size);
  color: var(--text-text-black);
  font-family: var(--subhead-2);
}
