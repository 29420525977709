.t11Icon {
    width: 120px;
    height: 100px;
    object-fit: cover;
  }
  .qstock,
  .t11Icon {
    position: relative;
  }
  .t11Parent {
    width: 242px;
    height: 83px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
  }
  .shapeIcon {
    width: 21px;
    position: relative;
    cursor: pointer;
    height: 12px;
  }
  .frameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .frameChild {
    width: 50px;
    position: relative;
    border-radius: 50px;
    height: 30px;
    object-fit: cover;
  }
  .canKra,
  .krc {
    position: relative;
    line-height: 20px;
  }
  .krc {
    font-size: var(--subhead-2-size);
    line-height: 18px;
    color: var(--text-secondary);
  }
  .canKraParent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-11xs);
  }
  .icons16pxArrowdown {
    width: 16px;
    position: relative;
    height: 16px;
    object-fit: contain;
  }
  .frameGroup,
  .frameWrapper,
  .rectangleParent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .rectangleParent {
    width: 241px;
    flex-direction: row;
    padding: 0 var(--padding-3xs);
    box-sizing: border-box;
    gap: var(--gap-mini);
  }
  .frameGroup,
  .frameWrapper {
    flex-direction: column;
  }
  .frameWrapper {
    border-radius: var(--br-xs);
    background-color: var(--color-black);
    border: 1px solid var(--base-line);
    overflow: hidden;
    padding: 11px 0;
    font-size: var(--base-1-semibold-size);
    color: var(--text-text-white);
    font-family: var(--text-md-regular);
  }
  .frameGroup {
    gap: var(--gap-6xl);
  }
  .sayfalar {
    position: absolute;
    top: 8px;
    left: 16px;
    line-height: 18px;
  }
  .homePageIcon,
  .sayfalarWrapper {
    width: 240px;
    position: relative;
    height: 32px;
  }
  .homePageIcon {
    width: 25px;
    height: 25px;
    object-fit: cover;
  }
  .anaSayfa {
    flex: 1;
    position: relative;
    font-weight: 600;
  }
  .homePageParent {
    align-self: stretch;
    border-radius: var(--br-xs);
    background-color: var(--color-black);
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-xs) var(--padding-base);
    gap: var(--gap-3xs);
    color:#F5F5F5;
  }
  .stok {
    position: relative;
    font-weight: 500;
  }
  .frameParent4,
  .hangarParent {
    display: flex;
    justify-content: flex-start;
  }
  .hangarParent {
    width: 240px;
    border-radius: var(--br-xs);
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    padding: var(--padding-xs) var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-3xs);
  }
  .frameParent4 {
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-black);
  }
  .frameParent2,
  .frameParent3 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-9xs);
  }
  .frameParent2 {
    gap: var(--gap-base);
  }
  .frameItem {
    align-self: stretch;
    position: relative;
    border-top: 1px solid var(--base-line);
    box-sizing: border-box;
    height: 1px;
  }
  .frameParent1,
  .logoutParent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .logoutParent {
    width: 240px;
    cursor: pointer;

    border-radius: var(--br-xs);
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-3xs);
    color: var(--color-black);
  }
  .frameParent1 {
    align-self: stretch;
    flex-direction: column;
    gap: var(--gap-base);
  }
  .divider,
  .uiIconhelplight {
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .divider {
    align-self: stretch;
    border-radius: var(--br-11xs);
    background-color: var(--color-dark-grey);
    height: 2px;
    
  }
  .uiIconhelplight {
    width: 24px;
    height: 24px;
  }
  .sss {
    flex: 1;
    position: relative;
    letter-spacing: -0.01em;
    line-height: 24px;
    font-weight: 600;
  }
  .left {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-7xs);
  }
  .div {
    position: absolute;
    top: 4px;
    left: 6.7px;
    letter-spacing: -0.01em;
    font-weight: 600;
  }
  .wrapper {
    width: 20px;
    position: relative;
    border-radius: var(--br-9xs);
    background-color: var(--color-accent-04);
    height: 20px;
    display: none;
    text-align: center;
    font-size: var(--font-size-3xs);
    color: var(--color-black);
  }
  .dividerParent,
  .navItem {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
  }
  .navItem {
    border-radius: var(--br-5xs);
    flex-direction: row;
    align-items: center;
    padding: var(--padding-xs);
    gap: var(--gap-xs);
  }
  .dividerParent {
    flex-direction: column;
    align-items: flex-start;
  }
  .image6Icon,
  .tr {
    position: relative;
  }
  .tr {
    letter-spacing: -0.01em;
    line-height: 24px;
    font-weight: 600;
  }
  .image6Icon {
    width: 30px;
    border-radius: var(--br-9xs);
    height: 20px;
    object-fit: cover;
  }
  .toggle {
    flex: 1;
    border-radius: var(--br-13xl);
    background-color: var(--text-text-white);
    box-shadow: var(--button-01);
    overflow: hidden;
    padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
      var(--padding-5xs);
    gap: var(--gap-5xs);
  }
  .switch,
  .toggle,
  .toggle1 {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .toggle1 {
    flex: 1;
    border-radius: var(--br-13xl);
    padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
      var(--padding-5xs);
  }
  .switch {
    align-self: stretch;
    border-radius: var(--br-21xl);
    background-color: var(--color-whitesmoke-200);
    padding: var(--padding-9xs);
    gap: var(--gap-9xs);
  }
  .bottom,
  .frameDiv,
  .frameParent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bottom {
    align-self: stretch;
    justify-content: center;
    gap: var(--gap-5xs);
    font-size: var(--base-1-semibold-size);
    color: var(--color-dark-grey-v2);
  }
  .frameDiv,
  .frameParent {
    justify-content: flex-start;
  }
  .frameDiv {
    gap: 52px;
    font-size: var(--subhead-2-size);
    color: var(--text-secondary);
    font-family: var(--text-md-regular);
  }
  .frameParent {
    gap: var(--gap-mini);
  }
  .sidebar,
  .ymenuopen {
    overflow: hidden;
    display: flex;
  }
  .sidebar {
    align-self: stretch;
    width: 275px;
    border-radius: var(--br-xs) 0 var(--br-xs) var(--br-xs);
    background-color: #F5F5F5;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 9px 29px;
    box-sizing: border-box;
  }
  .ymenuopen {
    background-color: var(--color-gray-200);
    height:   "100%";
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    font-size: 31px;
    color: var(--color-black);
    font-family: var(--font-fredoka-one);
  }
  