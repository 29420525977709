.icon {
  width: 25px;
  position: relative;
  height: 35px;
  object-fit: cover;
}
.aliVeli,
.irketAd {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.irketAd {
  font-size: var(--caption-2-medium-size);
  line-height: 12px;
  font-weight: 500;
  color: var(--color-dark-grey-v2);
}
.name,
.user {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.user {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}
.div {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}
.number {
  width: 24px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--color-accent-01);
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.addIcon {
  width: 8px;
  position: relative;
  height: 8px;
}
.icon1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xl);
  border: 2px solid var(--neutral-03);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.iconadd {
  display: none;
}
.iconadd,
.uiIconclosefilled {
  cursor: pointer;
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconRightcouple1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.iconRightcouple,
.userParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iconRightcouple {
  justify-content: flex-end;
  text-align: center;
  color: var(--neutral-07);
}
.userParent {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-29xl);
}
.homePageIcon {
  width: 25px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.anaSayfa {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.div1 {
  position: absolute;
  top: 4px;
  left: 6.7px;
  letter-spacing: -0.01em;
  font-weight: 600;
}
.wrapper {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-03);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
  color: var(--color-light-white);
}
.hangarIcon {
  width: 23px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.left1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.container {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-07);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-xs);
}
.frame,
.wrapper1 {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.frame {
  background-color: var(--color-accent-01);
  display: none;
}
.wrapper1 {
  background-color: var(--color-accent-04);
}
.iconmenuIcon,
.wrapper2 {
  width: 24px;
  position: relative;
  height: 24px;
}
.wrapper2 {
  width: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-04);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem5 {
  width: 240px;
  border-radius: var(--br-5xs);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-xs);
}
.logoutIcon {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: cover;
}
.navItemParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-dark-grey-v2);
}
.divider,
.navItemParent,
.top {
  align-self: stretch;
}
.top {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.divider {
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--color-dark-grey);
  height: 2px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.2;
}
.dividerParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.image6Icon,
.tr {
  position: relative;
}
.tr {
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.image6Icon {
  width: 30px;
  border-radius: var(--br-9xs);
  height: 20px;
  object-fit: cover;
}
.toggle {
  background-color: var(--color-light-white);
  box-shadow: var(--button-01);
  overflow: hidden;
  gap: var(--gap-5xs);
}
.toggle,
.toggle1 {
  flex: 1;
  border-radius: var(--br-13xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
}
.navigationDrawer,
.switch {
  align-self: stretch;
  display: flex;
}
.switch {
  border-radius: var(--br-21xl);
  background-color: var(--color-whitesmoke-200);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
}
.navigationDrawer {
  width: 300px;
  background-color: var(--color-light-white);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  box-sizing: border-box;
}
.label {
  width: 36px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}
.searchSmIcon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.helpIcon {
  width: 16px;
  position: relative;
  height: 16px;
  display: none;
}
.hintText,
.input {
  color: var(--color-slategray);
}
.input {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--color-light-white);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--color-lightgray-100);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-sm);
  gap: var(--gap-5xs);
  font-size: var(--text-md-regular-size);
}
.hintText {
  width: 320px;
  position: relative;
  line-height: 20px;
  display: none;
}
.inputField,
.inputFieldBase {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputFieldBase {
  align-self: stretch;
  gap: var(--gap-7xs);
}
.inputField {
  flex: 1;
  overflow: hidden;
}
.bell02Icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.bellChild {
  width: 8px;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 14.5px;
  border-radius: 50%;
  background-color: var(--color-crimson);
  height: 8px;
  z-index: 1;
}
.bell,
.menu01Parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.bell {
  align-items: flex-start;
  position: relative;
  gap: var(--gap-5xs);
}
.menu01Parent {
  align-self: stretch;
  background-color: #F5F5F5;
  align-items: center;
  padding: var(--padding-5xs) var(--padding-base);
  gap: 0 16px;
}
.profit {
  position: relative;
  letter-spacing: 0.1px;
  font-weight: 600;
}
.midRisk1 {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 37px);
  color: var(--color-sandybrown);

  letter-spacing: 0.09px;
  font-weight: 500;
}
.midRisk1iptal {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 37px);
  color: var(--color-crimson);

  letter-spacing: 0.09px;
  font-weight: 500;
}
.midRisk1ok {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 37px);
  color: var(--color-forestgreen);

  letter-spacing: 0.09px;
  font-weight: 500;
}
.midRisk1new {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 37px);
  color: var(--color-khaki);

  letter-spacing: 0.09px;
  font-weight: 500;
}
.selectorIcon {
  width: 146px;
  position: relative;
  height: 3px;
  object-fit: contain;
}
.profitParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  gap: 18px;
}
.candidates {
  position: relative;
  letter-spacing: 0.1px;
  font-weight: 500;
  color: var(--color-gray-100);
}
.frameContainer {
  position: absolute;
  top: 18px;
  left: calc(50% - 196px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 42px;
}
.separatorIcon {
  position: absolute;
  height: 1.73%;
  width: 100%;
  top: 98.27%;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.tabMenu {
  align-self: stretch;
  position: relative;
  height: 81px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: 15px;
  color: var(--color-mediumslateblue);
}
.shape {
  border-radius: 6.32px;
  background-color: var(--color-mediumslateblue);
}
.btnSend,
.shape {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.componenticonicPlus {
  position: absolute;
  height: 66.6%;
  width: 66.6%;
  top: 16.6%;
  right: 16.8%;
  bottom: 16.8%;
  left: 16.6%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.add {
  width: 50px;
  position: relative;
  height: 50px;
}
.frameGroup {
  align-self: stretch;
  height: 257px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 var(--padding-81xl);
  box-sizing: border-box;
  gap: var(--gap-6xl);
}
.shape1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--color-light-white);
}
.weekly,
.weekly1 {
  position: relative;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.weekly1 {
  color: var(--color-mediumslateblue);
}
.weeklyParent {
  position: absolute;
  top: calc(50% - 8.5px);
  left: calc(50% - 112px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-mini);
}
.timeline {
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
}
.fundsListing {
  width: 778px;
  height: 38px;
  top: -45px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-dimgray-100);
}
.image8Icon {
  width: 45px;
  position: relative;
  height: 45px;
  object-fit: cover;
}
.industries {
  position: relative;
  font-size: var(--caption-2-medium-size);
  letter-spacing: 0.09px;
  font-weight: 500;
  color: var(--color-darkslategray-100);
}
.image8Parent,
.newWorldFundGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.image8Parent {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-3xs);
}
.shape2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-red);
  box-sizing: border-box;
  opacity: 0.25;
  mix-blend-mode: normal;
}
.midRisk1 {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 14px);
  letter-spacing: 0.09px;
  font-weight: 500;
}
.midRisk {
  width: 148px;
  position: relative;
  height: 30px;
  overflow: hidden;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--caption-2-medium-size);
  color: var(--color-red);
}
.frameParent3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.separator {
  width: 369.8px;
  position: relative;
  background-color: var(--color-whitesmoke-100);
  height: 1px;
}
.div9,
.year1 {
  position: relative;
  letter-spacing: 0.09px;
}
.div9 {
  font-size: var(--font-size-3xs);
  letter-spacing: 0.1px;
  font-weight: 600;
}
.year,
.yearParent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.year {
  overflow: hidden;
  flex-direction: column;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-12xs);
}
.yearParent {
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-mini);
}
.div13,
.year8,
.yearParent {
  align-self: stretch;
}
.div13 {
  position: relative;
  font-size: var(--font-size-3xs);
  letter-spacing: 0.1px;
  font-weight: 600;
}
.year8 {
  overflow: hidden;
  align-items: center;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-12xs);
}
.frameParent2,
.frameParent4,
.newWorldFundParent,
.year8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.frameParent4 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-sm);
  font-size: var(--caption-2-medium-size);
  color: var(--color-dimgray-100);
}
.frameParent2,
.newWorldFundParent {
  align-items: center;
}
.frameParent2 {
  align-self: stretch;
  gap: var(--gap-6xl);
}
.newWorldFundParent {
  width: 399.8px;
  cursor: pointer;
  border-radius: var(--br-mini);
  background-color: var(--color-light-white);
  padding: var(--padding-mini);
  box-sizing: border-box;
  gap: var(--gap-3xs);
}
.frameParent1,
.relatedResult {
  align-self: stretch;
  display: flex;
  justify-content: center;
}
.frameParent1 {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 50px;
}
.relatedResult {
  border-radius: var(--br-mini);
  border: 1px solid var(--color-gainsboro-100);
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-mini) var(--padding-8xs);
}
.frameParent,
.relatedResultWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.relatedResultWrapper {
  align-self: stretch;
  margin-left: 10px;
  margin-right: 10px;

  padding: var(--padding-mini) 0 var(--padding-731xl);
  color: var(--color-gray-100);
}
.frameParent {
  flex: 1;
  gap: var(--gap-8xs);
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-200);
}
.gelengiden {
  width: 100%;
  position: relative;
  background-color: var(--color-gray-200);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--base-1-semibold-size);
  color: var(--color-light-body-text);
  font-family: var(--text-md-regular);
}
