.icon {
  width: 50px;
  position: relative;
  height: 35px;
  object-fit: cover;
  align-items: flex-start;
  justify-content: flex-start;
}
.aliVeli,
.irketAd {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.overviewParent {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.irketAd {
  font-size: var(--caption-2-medium-size);
  line-height: 12px;
  font-weight: 500;
  color: var(--color-dark-grey-v2);
}
.name,
.user {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.user {
  flex-direction: row;
  align-items: center;
  gap: 0 12px;
}
.div {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}
.number {
  width: 24px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--color-accent-01);
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.addIcon {
  width: 8px;
  position: relative;
  height: 8px;
}
.icon1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 24px;
  border: 2px solid var(--neutral-03);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.iconadd {
  display: none;
}
.iconadd,
.uiIconclosefilled {
  cursor: pointer;
  width: 24px;
  cursor: pointer;
  z-index: 999;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconRightcouple1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 12px;
}
.iconRightcouple,
.userParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iconRightcouple {
  justify-content: flex-end;
  text-align: center;
  color: var(--neutral-07);
}
.userParent {
  align-self: stretch;
  justify-content: flex-start;
  gap: 0 48px;
}
.homePageIcon {
  width: 25px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.anaSayfa {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 6px;
}
.div1 {
  position: absolute;
  top: 4px;
  left: 6.7px;
  letter-spacing: -0.01em;
  font-weight: 600;
}
.wrapper {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-03);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: #FFCD38;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: 0 12px;
  color: #F5F5F5;
}
.hangarIcon {
  width: 23px;
  position: relative;
  height: 25px;
  object-fit: cover;
}
.left1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.container {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-07);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: 0 12px;
}
.frame,
.wrapper1 {
  width: 20px;
  position: relative;
  border-radius: var(--br-9xs);
  height: 20px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.frame {
  background-color: var(--color-accent-01);
  display: none;
}
.wrapper1 {
  background-color: var(--color-accent-04);
}
.iconmenuIcon,
.wrapper2 {
  width: 24px;
  position: relative;
  height: 24px;
}
.wrapper2 {
  width: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--color-accent-04);
  height: 20px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.navItem5 {
  width: 290px;
  border-radius: var(--br-5xs);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: 0 12px;
}
.logoutIcon {
  width: 24px;
  position: relative;
  height: 24px;
  object-fit: cover;
}
.navItemParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px 0;
  color: var(--color-dark-grey-v2);
}
.divider,
.navItemParent,
.top {
  align-self: stretch;
}
.top {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px 0;
}
.divider {
  position: relative;
  border-radius: 2px;
  background-color: var(--color-dark-grey);
  height: 2px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.2;
}
.dividerParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.image6Icon,
.tr {
  position: relative;
}
.tr {
  letter-spacing: -0.01em;
  line-height: 24px;
  font-weight: 600;
}
.image6Icon {
  width: 30px;
  border-radius: var(--br-9xs);
  height: 20px;
  object-fit: cover;
}
.toggle {
  flex: 1;
  border-radius: var(--br-13xl);
  background-color: #F5F5F5;
  box-shadow: var(--button-01);
  overflow: hidden;
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
  gap: 0 8px;
}
.switch,
.toggle,
.toggle1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.toggle1 {
  flex: 1;
  border-radius: var(--br-13xl);
  padding: var(--padding-9xs) var(--padding-base) var(--padding-9xs)
    var(--padding-5xs);
}
.switch {
  align-self: stretch;
  border-radius: 40px;
  background-color: #f0f0f0;
  padding: var(--padding-9xs);
  gap: 0 4px;
}
.bottom,
.navigationDrawer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.bottom {
  padding: 0 0 var(--padding-6xl);
  gap: 8px 0;
  color: var(--color-dark-grey-v2);
}
.navigationDrawer {
  width: 300px;
  background-color: #F5F5F5;
  padding: var(--padding-8xs);
  box-sizing: border-box;
}
.label {
  width: 36px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}
.searchSmIcon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 8px;
}
.helpIcon {
  width: 16px;
  position: relative;
  height: 16px;
  display: none;
}
.hintText,
.input {
  color: var(--color-slategray);
}
.input {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--color-lightgray-100);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-sm);
  gap: 0 8px;
  font-size: var(--text-md-regular-size);
}
.hintText {
  width: 320px;
  position: relative;
  line-height: 20px;
  display: none;
}
.inputField,
.inputFieldBase {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputFieldBase {
  align-self: stretch;
  gap: 6px 0;
}
.inputField {
  flex: 1;
  overflow: hidden;
}
.bell02Icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.bellChild {
  width: 8px;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 14.5px;
  border-radius: 50%;
  background-color: var(--color-crimson);
  height: 8px;
  z-index: 1;
}
.bell,
.menu01Parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.bell {
  align-items: flex-start;
  position: relative;
  gap: 0 8px;
}
.menu01Parent {
  align-self: stretch;
  background-color: #F5F5F5;
  align-items: center;
  padding: var(--padding-5xs) var(--padding-base);
  gap: 0 16px;
}
.span {
  color: var(--color-dimgray-100);
}
.buAy {
  color: var(--color-darkslategray-100);
}
.showThisYearContainer {
  position: relative;
  letter-spacing: 0.08px;
}
.icDropdownIcon {
  width: 16px;
  position: relative;
  height: 16px;
}
.showThisYearParent {
  width: 137px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.overview1,
.show {
  position: absolute;
  left: 0;
}
.show {
  height: 34.69%;
  top: 67.35%;
  bottom: -2.04%;
  width: 150px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.overview2x,
.overviewParentx {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.overview2x {
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 var(--padding-3xs);
  text-align: center;
  font-size: var(--caption-2-medium-size);
}
.overview1x {
  position: relative;
  font-weight: 600;
}
.overview1 {
  width: 34.13%;
  top: calc(50% - 24.5px);
  font-size: 18px;
  font-weight: 600;
  color: var(--color-gray-100);
  display: inline-block;
}
.group {
  width: 150px;
  position: relative;
  height: 49px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.shape {
  border-radius: 6.32px;
  background-color: var(--color-mediumslateblue);
}
.btnSend,
.shape {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.componenticonicDownload {
  position: absolute;
  height: 66.56%;
  width: 66.57%;
  top: 16.56%;
  right: 16.71%;
  bottom: 16.88%;
  left: 16.71%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.download {
  width: 35.3px;
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 15.5px);
  left: calc(50% + 111px);
  height: 32px;
  z-index: 1;
}
.overview {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  position: relative;
  gap: var(--gap-3xs);
  color: var(--color-lightslategray);
}
.overviewx {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
}
.art {
  margin: 0;
}
.comparedTo19000Container {
  position: absolute;
  width: 79.32%;
  top: calc(50% + 12.5px);
  left: 10.34%;
  font-size: var(--font-size-2xs);
  letter-spacing: 0.07px;
  line-height: 16px;
  color: var(--color-lightslategray);
  display: inline-block;
}
.div10,
.div9 {
  font-weight: 600;
}
.div9 {
  position: absolute;
  width: 46.89%;
  top: calc(50% - 22.5px);
  left: 10.68%;
  font-size: var(--font-size-3xl);
  letter-spacing: 0.1px;
  display: inline-block;
}
.div10 {
  position: relative;
}
.icDownIcon {
  width: 12px;
  position: relative;
  height: 12px;
}
.parent {
  position: absolute;
  top: 15px;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--color-mediumseagreen-100);
}




.purchasex,
.purchase2x,
.purchase4x {
  border-radius: var(--br-8xs);
  overflow: hidden;
  flex-direction: column;
  cursor: pointer;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-base);
}
.purchasex {
  background-color: #12e327;
  display: flex;
  color: #F5F5F5;
}
.purchase2x,
.purchase4x {
  background-color: #F5F5F5;
}
.purchase2x {
  display: flex;
}




.purchase1 {
  position: absolute;
  top: calc(50% - 46.5px);
  left: 15px;
  letter-spacing: 0.1px;
  font-weight: 600;
  text-align: center;
}
.purchase {
  flex: 1;
  position: relative;
  height: 123px;
  overflow: hidden;
}
.div11,
.sales1 {
  position: absolute;
  letter-spacing: 0.1px;
  font-weight: 600;
}
.div11 {
  width: 31.04%;
  top: calc(50% - 22.5px);
  left: 10.34%;
  font-size: var(--font-size-3xl);
  display: inline-block;
}
.sales1 {
  top: calc(50% - 46.5px);
  left: 10px;
  text-align: center;
}
.purchaseParentx {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.purchaseParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0 10px;
}
.comparedTo165 {
  width: 79.32%;
  top: calc(50% + 12.5px);
  left: 10.35%;
  font-size: var(--font-size-2xs);
  letter-spacing: 0.07px;
  line-height: 16px;
  color: var(--color-lightslategray);
  display: inline-block;
}
.comparedTo165,
.div13,
.parent2 {
  position: absolute;
}
.div13 {
  width: 31.04%;
  top: calc(50% - 22.5px);
  left: 10.35%;
  font-size: var(--font-size-3xl);
  letter-spacing: 0.1px;
  font-weight: 600;
  display: inline-block;
}
.parent2 {
  top: 15px;
  right: -0.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0 4px;
  color: #fc5a5a;
}
.return1 {
  position: absolute;
  top: calc(50% - 46.5px);
  left: 0;
  letter-spacing: 0.1px;
  font-weight: 600;
  text-align: center;
  display: inline-block;
  width: 125px;
}
.returnWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.div15,
.return3 {
  position: absolute;
  letter-spacing: 0.1px;
  font-weight: 600;
}
.div15 {
  width: 31.04%;
  top: calc(50% - 22.5px);
  left: 10.35%;
  display: inline-block;
}
.return3 {
  top: calc(50% - 46.5px);
  left: calc(50% - 57.75px);
  font-size: var(--caption-2-medium-size);
  text-align: center;
}
.returnContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-3xl);
}
.frameContainer,
.frameGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0 13px;
}
.frameGroup {
  flex-direction: column;
  gap: 13px 0;
  font-size: var(--caption-2-medium-size);
}
.shapeIcon,
.shapeIcon1,
.shapeIcon2,
.shapeIcon3 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.shapeIcon1,
.shapeIcon2,
.shapeIcon3 {
  height: 61.88%;
  width: 50.04%;
  top: 38.13%;
  right: 49.96%;
}
.shapeIcon2,
.shapeIcon3 {
  height: 6.25%;
  width: 24.25%;
  top: 93.75%;
  right: 50.04%;
  left: 25.71%;
}
.shapeIcon3 {
  height: 100%;
  width: 50.04%;
  top: 0;
  right: -0.07%;
  left: 50.04%;
}
.div17 {
  position: absolute;
  top: calc(50% - 19.2px);
  left: calc(50% - 32.95px);
  letter-spacing: 0.07px;
  font-weight: 600;
}
.visitorsThisYear {
  position: absolute;
  top: calc(50% + 10px);
  left: calc(50% - 26.95px);
  font-size: var(--font-size-4xs);
  letter-spacing: 0.06px;
  color: var(--color-dimgray-100);
}
.chart {
  width: 700px;
  height: 200px;
  left: 50px;
  font-size: var(--font-size-mid-9);
}
.chartx {
  width: 700px;
  position: relative;
  left: -400px;
  font-size: var(--font-size-mid-9);
}
.chartiki {
  width: 350px;
  height: 200px;
  left: -320px;
  position: relative;
  font-size: var(--font-size-mid-9);
}
.chart,
.k,
.shape5 {
  position: relative;
}
.shape5 {
  width: 10px;
  border-radius: var(--br-7xs);
  background-color: var(--color-mediumslateblue);
  height: 12px;
}
.k {
  letter-spacing: 0.1px;
  line-height: 24px;
}
.amazon {
  overflow: hidden;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 9px;
}
.shape6,
.shape7 {
  width: 10px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--color-sandybrown);
  height: 12px;
}
.shape7 {
  background-color: var(--color-mediumseagreen-200);
}
.ebayParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.shape8 {
  width: 10px;
  position: relative;
  border-radius: var(--br-7xs);
  background-color: var(--color-goldenrod);
  height: 12px;
}
.amazonParent,
.parent3 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.amazonParent {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0 var(--padding-mini);
  gap: 10px 45px;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-100);
}
.parent3 {
  border-radius: var(--br-xl);
  background-color: #F5F5F5;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-8xs) 0;
  gap: 13px 0;
}
.div18 {
  position: relative;
  font-size: var(--text-md-regular-size);
  letter-spacing: 0.1px;
  font-weight: 600;
}
.tm,
.weekly {
  position: relative;
  cursor: pointer;
  letter-spacing: 0.2px;
}
.weekly {
  color: var(--color-mediumslateblue);
}
.amazonGroup,
.tmParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: var(--color-dimgray-100);
}
.amazonGroup {
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-mini);
  gap: 10px 45px;
  color: var(--color-darkslategray-100);
}
.mask,
.parent4 {
  border-radius: var(--br-xl);
  background-color: #F5F5F5;
}
.parent4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0;
  gap: 13px 0;
  height: 500px;
  font-size: var(--font-size-sm);
}
.mask {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.barmask {
  
  top: 100px;
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
}
.k9,
.mask,
.shape13 {
  position: absolute;
}
.shape13 {
  height: 7.14%;
  width: 89.73%;
  top: 92.86%;
  right: 0;
  bottom: 0;
  left: 10.27%;
  background-color: var(--color-whitesmoke-100);
}
.k9 {
  width: 6.84%;
  top: calc(50% - 7px);
  left: 0;
  letter-spacing: 0.09px;
  display: inline-block;
}
.k8 {
  width: 311.5px;
  position: relative;
  height: 14px;
  overflow: hidden;
  flex-shrink: 0;
}
.div21,
.shape14 {
  position: absolute;
}
.shape14 {
  height: 7.14%;
  width: 87.34%;
  top: 92.86%;
  right: 0;
  bottom: 0;
  left: 12.66%;
  background-color: var(--color-whitesmoke-100);
}
.div21 {
  width: 10%;
  top: calc(50% - 7px);
  left: -0.66%;
  letter-spacing: 0.09px;
  display: inline-block;
}
.div20 {
  width: 320px;
  position: relative;
  height: 14px;
  overflow: hidden;
  flex-shrink: 0;
}
.div23 {
  width: 10.34%;
  top: calc(50% - 7px);
  left: -1%;
  letter-spacing: 0.09px;
  display: inline-block;
}
.div23,
.div27,
.shape17 {
  position: absolute;
}
.div27 {
  width: 5.6%;
  top: calc(50% - 7px);
  left: -0.69%;
  letter-spacing: 0.09px;
  display: inline-block;
}
.shape17 {
  height: 7.14%;
  width: 91.61%;
  top: 92.86%;
  right: 0;
  bottom: 0;
  left: 8.39%;
  background-color: var(--color-whitesmoke-100);
}
.div26 {
  width: 305.1px;
  position: relative;
  height: 14px;
  overflow: hidden;
  flex-shrink: 0;
}
.may1,
.value {
  position: absolute;
}
.value {
  top: 94px;
  left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 23px 0;
}
.may1 {
  width: 107.42%;
  top: calc(50% + 73.5px);
  left: -3.67%;
  letter-spacing: 0.1px;
  line-height: 24px;
  display: inline-block;
}
.shapeIcon8,
.shapeIcon9 {
  width: 25.94%;
  bottom: 17.44%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.shapeIcon8 {
  position: absolute;
  height: 82.56%;
  top: 0;
  right: 14.85%;
  left: 59.21%;
}
.shapeIcon9 {
  height: 56.41%;
  top: 26.15%;
  right: 55.55%;
  left: 18.52%;
}
.apr1,
.may,
.shapeIcon9 {
  position: absolute;
}
.may {
  height: 100%;
  width: 12.16%;
  top: 0;
  right: 17.57%;
  bottom: 0;
  left: 70.26%;
  overflow: hidden;
}
.apr1 {
  width: 104.42%;
  top: calc(50% + 63px);
  left: -4.3%;
  letter-spacing: 0.1px;
  line-height: 24px;
  display: inline-block;
}
.shapeIcon10,
.shapeIcon11 {
  width: 30.46%;
  bottom: 19.54%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.shapeIcon10 {
  position: absolute;
  height: 80.46%;
  top: 0;
  right: 13.02%;
  left: 56.51%;
}
.shapeIcon11 {
  height: 45.98%;
  top: 34.48%;
  right: 60.82%;
  left: 8.72%;
}
.apr,
.mar1,
.shapeIcon11 {
  position: absolute;
}
.apr {
  height: 89.23%;
  width: 10.36%;
  top: 10.77%;
  right: 36.49%;
  bottom: 0;
  left: 53.15%;
  overflow: hidden;
}
.mar1 {
  width: 103.96%;
  top: calc(50% + 53px);
  left: -3.96%;
  letter-spacing: 0.1px;
  line-height: 24px;
  display: inline-block;
}
.shapeIcon12,
.shapeIcon13 {
  width: 28.02%;
  bottom: 22.08%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.shapeIcon12 {
  position: absolute;
  height: 77.92%;
  top: 0;
  right: 15.94%;
  left: 56.04%;
}
.shapeIcon13 {
  height: 58.44%;
  top: 19.48%;
  right: 60%;
  left: 11.98%;
}
.feb1,
.mar,
.shapeIcon13 {
  position: absolute;
}
.mar {
  height: 78.97%;
  width: 11.26%;
  top: 21.03%;
  right: 53.61%;
  bottom: 0;
  left: 35.13%;
  overflow: hidden;
}
.feb1 {
  width: 104.12%;
  top: calc(50% + 68px);
  left: 0;
  letter-spacing: 0.1px;
  line-height: 24px;
  display: inline-block;
}
.shapeIcon14,
.shapeIcon15 {
  width: 29.18%;
  bottom: 18.48%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.shapeIcon14 {
  position: absolute;
  height: 81.52%;
  top: 0;
  right: 12.47%;
  left: 58.35%;
}
.shapeIcon15 {
  height: 70.65%;
  top: 10.87%;
  right: 58.35%;
  left: 12.47%;
}
.feb,
.jun1,
.shapeIcon15 {
  position: absolute;
}
.feb {
  height: 94.36%;
  width: 10.82%;
  top: 5.64%;
  right: 71.62%;
  bottom: 0;
  left: 17.56%;
  overflow: hidden;
}
.jun1 {
  width: 104.12%;
  top: calc(50% + 73.5px);
  left: 0;
  letter-spacing: 0.1px;
  line-height: 24px;
  display: inline-block;
}
.shapeIcon16,
.shapeIcon17 {
  width: 29.18%;
  bottom: 17.44%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.shapeIcon16 {
  position: absolute;
  height: 82.56%;
  top: 0;
  right: 12.47%;
  left: 58.35%;
}
.shapeIcon17 {
  height: 41.03%;
  top: 41.54%;
  right: 58.35%;
  left: 12.47%;
}
.jan1,
.jun,
.shapeIcon17 {
  position: absolute;
}
.jun {
  height: 100%;
  width: 10.82%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 89.18%;
  overflow: hidden;
}
.jan1 {
  width: 100%;
  top: calc(50% + 58px);
  left: 0;
  letter-spacing: 0.1px;
  line-height: 24px;
  display: inline-block;
}
.shapeIcon18 {
  height: 79.27%;
  width: 29.18%;
  top: 0;
  right: 12.47%;
  bottom: 20.73%;
  left: 58.35%;
  max-width: 100%;
  max-height: 100%;
}
.jan,
.shapeIcon18,
.shapeIcon19 {
  position: absolute;
  overflow: hidden;
}
.shapeIcon19 {
  height: 48.78%;
  width: 29.18%;
  top: 30.49%;
  right: 58.35%;
  bottom: 20.73%;
  left: 12.47%;
  max-width: 100%;
  max-height: 100%;
}
.jan {
  height: 84.1%;
  width: 10.82%;
  top: 15.9%;
  right: 89.18%;
  bottom: 0;
  left: 0;
}
.monthGraph {
  position: absolute;
  height: 63.93%;
  width: 74%;
  top: 31.15%;
  right: 6.33%;
  bottom: 4.92%;
  left: 19.67%;
  text-align: center;
  font-size: var(--font-size-sm);
}
.month,
.shape18 {
  position: absolute;
  left: 0;
}
.month {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: right;
}
.shape18 {
  height: 41.67%;
  width: 8.75%;
  top: 29.17%;
  right: 91.25%;
  bottom: 29.17%;
  border-radius: var(--br-7xs);
  background-color: var(--color-mediumslateblue);
}
.onlineSales1 {
  position: absolute;
  top: calc(50% - 12px);
  left: 14.32%;
  width: 250px;
  letter-spacing: 0.1px;
  line-height: 24px;
}
.onlineSales,
.shape19 {
  width: 97.1px;
  position: relative;
  height: 24px;
}
.shape19 {
  width: 8.5px;
  border-radius: var(--br-7xs);
  background-color: var(--color-mediumseagreen-200);
  height: 10px;
}
.offlineSales,
.onlineSalesParent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.offlineSales {
  overflow: hidden;
  align-items: center;
  gap: 0 5px;
}
.onlineSalesParent {
  position: absolute;
  top: 60px;
  left: 200px;
  align-items: flex-start;
  gap: 0 25px;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-100);
}
.salesReport1 {
  position: absolute;
  width: 29.37%;
  top: calc(50% - 137.5px);
  left: 5%;
  font-size: var(--text-md-regular-size);
  letter-spacing: 0.1px;
  font-weight: 600;
  color: var(--color-gray-100);
  display: inline-block;
}
.newCustomers,
.salesReport {
  position: relative;
  flex-shrink: 0;
}
.salesReport {
  align-self: stretch;
  height: 405px;
  overflow: hidden;
  font-size: var(--caption-2-medium-size);
  color: var(--color-lightslategray);
}
.newCustomers {
  width: 133.3px;
  letter-spacing: 0.1px;
  font-weight: 600;
  display: inline-block;
}
.viewMoreCustomers {
  cursor: pointer;
  position: relative;
  font-size: var(--caption-2-medium-size);
  letter-spacing: 0.8px;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--color-mediumslateblue);
  text-align: center;
}
.newCustomersParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 67px;
}
.ppIcon {
  width: 36px;
  position: relative;
  height: 36px;
  object-fit: cover;
}
.isabellaMoran {
  position: relative;
  letter-spacing: 0.1px;
  font-weight: 600;
}
.isabellaMoranx {
  position: relative;
  letter-spacing: 0.1px;
  font-weight: 600;
}
.customerId00222 {
  position: relative;
  font-size: var(--caption-2-medium-size);
  color: var(--color-lightslategray);
}
.isabellaMoranParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px 0;
}
.customer,
.ppParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.ppParent {
  width: 270px;
  gap: 0 10px;
}
.customer {
  padding: var(--padding-xs) var(--padding-base);
}
.customerWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.viewMoreCustomers1 {
  width: 162px;
  cursor: pointer;
  position: relative;
  font-size: var(--caption-2-medium-size);
  letter-spacing: 0.8px;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--color-mediumslateblue);
  text-align: center;
  display: inline-block;
  flex-shrink: 0;
}
.frameParent1,
.frameParent2 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.frameParent2 {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--font-size-sm);
}
.frameParent1 {
  border-radius: var(--br-xl);
  background-color: #F5F5F5;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-8xs);
  gap: 16px 0;
}
.frameParent,
.instanceParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameParent {
  align-self: stretch;
  align-items: flex-start;
  padding: 0 var(--padding-mini);
  gap: 24px 0;
  font-size: var(--text-md-regular-size);
  color: var(--color-gray-100);
}
.instanceParent {
  flex: 1;
  align-items: center;
  padding: 0 0 var(--padding-6xl);
  gap: 22px 0;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-200);
}
.anaSayfaWeb {
  width: 100%;
  position: relative;
  background-color: #fafafb;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--base-1-semibold-size);
  color: var(--color-light-body-text);
  font-family: var(--text-md-regular);
}
