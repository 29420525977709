@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inria+Sans:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --text-md-regular: Inter;
  --subhead-2: "Plus Jakarta Sans";
  --font-poppins: Poppins;
  --font-fredoka-one: "Fredoka One";
  --font-inria-sans: "Inria Sans";

  /* font sizes */
  --caption-2-medium-size: 12px;
  --subhead-2-size: 14px;
  --text-md-regular-size: 16px;
  --font-size-4xs: 9px;
  --font-size-mid-9: 17.9px;
  --font-size-3xl: 22px;
  --font-size-2xs: 11px;
  --heading-3-size: 18px;
  --base-1-semibold-size: 15px;
  --font-size-3xs: 10px;
  --font-size-5xl: 24px;
  --heading-2-size: 20px;
  --font-size-5xs: 8px;
  --component-heading-large-lg-size: 30px;
  --font-size-21xl: 40px;

  /* Colors */
  --color-gray-200: #fafafb;
  --color-gray-300: #818181;
  --color-gray-500: #2c2c2c;
  --color-light-body-text: #1a1b2d;
  --neutral-07: #1a1d1f;
  --color-gray-400: #0d1b34;
  --gray-900: #171923;
  --color-gray-100: #171725;
  --color-light-white: #fff;
  --primary-500: #5d55fa;
  --color-mediumslateblue: #FFCD38;
  --color-lightslategray-100: #8696bb;
  --color-lightslategray: #92929d;
  --color-darkslategray-100: #44444f;
  --color-darkslategray-200: #344154;
  --gray-700: #2d3748;
  --text-text-black: #333;
  --color-darkslategray-300: #053b50;
  --color-mediumseagreen-100: #3dd598;
  --color-mediumseagreen-200: #3cd598;
  --color-mediumseagreen-300: #26a371;
  --gray-50: #f7fafc;
  --color-whitesmoke-400: #f9f9f9;
  --color-whitesmoke-300: #f8f8f8;
  --color-whitesmoke-100: #f1f1f5;
  --color-whitesmoke-200: #f0f0f0;
  --neutral-03: #efefef;
  --grayscale-white-almost-white: #eee;
  --color-whitesmoke-500: #ededed;
  --color-goldenrod: #ffc542;
  --color-sandybrown: #ff974a;
  --color-dimgray-200: #6d6d6d;
  --color-dimgray-100: #696974;
  --color-dark-grey-v2: #535763;
  --gray-600: #4a5568;
  --color-limegreen-100: #04d300;
  --color-limegreen: #00ba1e;
  --color-crimson: #f30066;
  --color-slategray: #667085;
  --color-lightgray-100: #d0d5dd;
  --gray-300: #cbd5e0;
  --color-dark-grey: #d1d3d4;
  --grayscale-white-dark-white: #ccc;
  --color-accent-04: #b5ebcd;
  --color-black: #000;
  --color-accent-01: #ffbc99;
  --color-accent-07: #fb9b9b;
  --primary-primary-500: #FFCD38;
  --color-accent-03: #b1e6fc;
  --color-gainsboro-100: #e2e2ea;
  --grayscale-white-shaded-white: #ddd;
  --color-red: #ff0101;
  --color-darkgray-200: #afafaf;
  --grayscale-gray-pale-gray: #aaa;
  --color-darkgray-100: #98999c;
  --grayscale-gray-light-gray: #999;
  --color-firebrick-100: #d53d3d;
  --error-error-500: #d11f19;
  --color-forestgreen: #589d0d;
  --color-khaki: #fff499;
  --color-darkgoldenrod: #937500;
  --error-error-200: #fabba2;
  --others-subtle-blue: #9bd8ff;
  --primary-primary-600: #005ca6;
  --color-salmon: #f06565;
  --green-500: #38a169;
  --color-seagreen: #09925b;
  --color-deepskyblue: #FFCD38;
  --gray-100: #edf2f7;
  --gray-200: #e2e8f0;
  --blue-500: #3182ce;

  /* Gaps */
  --gap-3xl: 22px;
  --gap-5xl: 24px;
  --gap-base: 16px;
  --gap-xl: 20px;
  --gap-3xs: 10px;
  --gap-7xs: 6px;
  --gap-48xl: 67px;
  --gap-6xl: 25px;
  --gap-8xs: 5px;
  --gap-smi: 13px;
  --gap-4xs: 9px;
  --gap-mini: 15px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-13xl: 32px;
  --gap-29xl: 48px;
  --gap-31xl: 50px;
  --gap-sm: 14px;
  --gap-12xs: 1px;
  --gap-11xs: 2px;
  --gap-23xl: 42px;
  --gap-lg: 18px;
  --gap-6xs: 7px;
  --gap-2xs: 11px;
  --gap-8xl: 27px;
  --gap-14xl: 33px;

  /* Paddings */
  --padding-6xl: 25px;
  --padding-mini: 15px;
  --padding-3xs: 10px;
  --padding-8xs: 5px;
  --padding-xs: 12px;
  --padding-base: 16px;
  --padding-5xs: 8px;
  --padding-sm: 14px;
  --padding-9xs: 4px;
  --padding-731xl: 750px;
  --padding-11xs: 2px;
  --padding-81xl: 100px;
  --padding-10xs: 3px;
  --padding-131xl: 150px;
  --padding-12xs: 1px;
  --padding-31xl: 50px;
  --padding-xl: 20px;
  --padding-5xl: 24px;
  --padding-13xl: 32px;
  --padding-6xs: 7px;

  /* Border radiuses */
  --br-xl: 20px;
  --br-7xs: 6px;
  --br-6xs-3: 6.3px;
  --br-8xs: 5px;
  --br-5xs: 8px;
  --br-21xl: 40px;
  --br-13xl: 32px;
  --br-9xs: 4px;
  --br-11xs: 2px;
  --br-5xl: 24px;
  --br-mini: 15px;
  --br-3xs: 10px;
  --br-6xs: 7px;
  --br-6xl: 25px;
  --br-xs: 12px;
  --br-237xl-9: 256.9px;
  --br-9980xl: 9999px;

  /* Effects */
  --button-01: 0px -1px 1px rgba(0, 0, 0, 0.04) inset,
    0px 2px 0px rgba(255, 255, 255, 0.25) inset,
    0px 4px 8px -4px rgba(0, 0, 0, 0.25);
  --base: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  --container-light: 0px 1px 0px #ccc;
}
