.image5Icon {
  width: 450px;
  position: relative;
  height: 320px;
  object-fit: cover;
}
.header {
  width: 1127px;
  height: 316px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  box-sizing: border-box;
}
.smallLabel {
  position: relative;
  border: none;
  outline: none;
  width: 450px;

  font-weight: 600;

}
.container,
.label {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.container {
  border-radius: var(--br-5xs);
  border: 1px solid var(--grayscale-white-dark-white);
  box-sizing: border-box;
  height: 35px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xs);
  color: var(--grayscale-white-dark-white);
}
.email,
.placeholder1 {
  display: flex;
  align-items: center;
}
.email {
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-5xs);
}
.placeholder1 {
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
}
.hideIcon {
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.xlLabel {
  position: relative;
  font-weight: 800;
}
.label2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ctaButton,
.password {
  align-self: stretch;
  display: flex;
}
.ctaButton {
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
  height: 55px;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) 0;
  box-sizing: border-box;
  font-size: var(--heading-3-size);
  color: var(--text-text-white);
}

.ctaButtonqr {
  align-self: stretch;
  display: flex;
  border-radius: var(--br-5xs);
  background-color: var(--color-black);
  height: 55px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: var(--padding-xs) 0;
  box-sizing: border-box;
  font-size: var(--heading-3-size);
  color: var(--text-text-white);
}
.password {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.nfcIcon {
  width: 45px;
  height: 45px;
  object-fit: cover;
}
.nfcIcon,
.smallLabel4 {
  position: relative;
}
.apple,
.thirdPartyLogin {
  display: flex;
  flex-direction: row;
}
.apple {
  flex: 1;
  border-radius: var(--br-5xs);
  border: 1px solid var(--grayscale-white-dark-white);
  box-sizing: border-box;
  height: 50px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.thirdPartyLogin {
  align-self: stretch;
  font-size: var(--text-md-regular-size);
}
.form,
.thirdPartyLogin,
.thirdPartyLogin1 {
  align-items: center;
  justify-content: flex-start;
}
.form {
  width: 512px;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-5xl);
}
.thirdPartyLogin1 {
  width: 272px;
  display: none;
  flex-direction: row;
  font-size: var(--text-md-regular-size);
}
.formKayit,
.shape {
  box-sizing: border-box;
}
.formKayit {
  width: 566px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-base);
  gap: var(--gap-5xl);
}
.shape {
  width: 68px;
  position: relative;
  border-radius: var(--br-3xs);
  border: 1px solid var(--primary-primary-500);
  height: 23px;
  opacity: 0.25;
  mix-blend-mode: normal;
  z-index: 0;
}
.midRisk1 {
  width: 45px;
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 7.5px);
  left: calc(50% - 23px);
  letter-spacing: 0.09px;
  font-weight: 500;
  display: inline-block;
  z-index: 1;
}
.midRisk {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 118px;
  position: relative;
  gap: var(--gap-3xs);
  text-align: center;
  color: var(--primary-primary-500);
  font-family: var(--text-md-regular);
}
.giriYaparak {
  white-space: pre-wrap;
}
.kullancSzlememizi {
  color: var(--primary-primary-500);
}
.kabulEtmiOlursunuz {
  color: var(--color-black);
}
.giriYaparakKullancContainer {
  flex: 1;
  position: relative;
  font-weight: 600;
}
.tcPrivacyControl {
  width: 304px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  text-align: center;
}
.body,
.girisSayfasi {
  display: flex;
  justify-content: flex-start;
}
.body {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-mini);
}
.girisSayfasi {
  width: 100%;
  position: relative;
  background-color: var(--text-text-white);
  height: 982px;
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--body-3-size);
  color: var(--text-text-black);
  font-family: var(--subhead-2);
}
